import ProjectView from "components/ProjectView";
import React from "react";
import "assets/styles/createview.scss";
import { Helmet } from "react-helmet";

const CreatView = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Create Project | Serpentor</title>
        <meta name="description" content="Create a new project in Serpentor." />
      </Helmet>
      <div className="flex flex-wrap createView_parent_container">
        <div className="w-full h-full project_view_parent">
          <ProjectView />
        </div>
      </div>
    </>
  );
};

export default CreatView;
