import React from "react";
import "assets/styles/histogram.scss";
import Skeleton from "react-loading-skeleton";

export default function HistogramSkeleton({}) {
  return (
    <div className="w-full flex flex-col rooot_class_skeketon_chart justify-between h-full shadow-lg bg-white px-4 py-4">
      <div>
        <Skeleton width="30%" height={10} />
        <Skeleton width="50%" height={20} />
      </div>
      <div className="historgram_chat_skeleton  ">
        <div className="vertical_parent_class hxs">
          <span className="skeleton-loader"></span>
        </div>
        <div className="vertical_parent_class hm">
          <span className="skeleton-loader"></span>
        </div>
        <div className="vertical_parent_class hl">
          <span className="skeleton-loader"></span>
        </div>
        <div className="vertical_parent_class hs">
          <span className="skeleton-loader"></span>
        </div>

        <div className="vertical_parent_class hxl">
          <span className="skeleton-loader"></span>
        </div>
        <div className="vertical_parent_class hm">
          <span className="skeleton-loader"></span>
        </div>
      </div>
    </div>
  );
}
