import React, { useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { CustomToast } from "utils/helpers";

const ProjectsTable = ({ heading, subHeading, data,loading }) => {
  const [expandedRows, setExpandedRows] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    domain: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    full_name: { value: null, matchMode: FilterMatchMode.EQUALS },
    created_at: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const history = useHistory();
  const onRowClickParent = (e) => {
    console.log(data);
    const { jobs, pid } = e?.data;
    const { status } = jobs[0];
    if (status * 100 > 0) {
      history.push(`/admin/tables/${pid}`);
    } else {
      CustomToast(
        "Feel free to return later as the data is currently in the process of being analyzed."
      );
    }
  };
  const dt = useRef(null);
  const projectUsers = [...new Set(data?.map(({ full_name }) => full_name))];
  const userUnique = projectUsers.map((node) => {
    return{
      label:node,
      value:node
    }
  })
  const allowExpansion = (row) => {
    const { jobs = [] } = row || {};
    if (jobs?.length <= 1) {
      return false;
    } else {
      return true;
    }
  };
  const onRowExpand = (event) => {
    return true;
  };

  const onRowCollapse = (event) => {
    return false;
  };

  const DomaineName = ({ domain }) => {
    return (
      <a href={domain} target="_blank" className="dlink">
        {domain}
      </a>
    );
  };
  const ProfileUser = ({ owner: { full_name } }) => {
    return (
      <span className={"ml-3 font-normal " + "text-blueGray-600"}>
        {full_name}
      </span>
    );
  };
  const StatusText = ({ jobs }) => {
    const { status, statusText } = jobs[0];
    return (
      <div className="flex flex-col gap-1 items-center w-full">
        <span className="mr-2 w-left ">{statusText}</span>
        <div className="relative w-right">
          <div className="overflow-hidden h-2 text-xs flex rounded bg-red-200">
            <div
              style={{ width: `${status * 100}%` }}
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-400"
            ></div>
          </div>
        </div>
      </div>
    );
  };
  const NestedStatus = ({ statusText, status }) => {
    // console.log();
    return (
      <div className="flex flex-col gap-1 items-center w-full">
        <span className="mr-2 w-left ">{statusText}</span>
        <div className="relative w-right">
          <div className="overflow-hidden h-2 text-xs flex rounded bg-red-200">
            <div
              style={{ width: `${status * 100}%` }}
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-400"
            ></div>
          </div>
        </div>
      </div>
    );
  };
  const DataStarted = ({ created_at }) => {
    return (
      <span className={"ml-3 font-normal " + "text-blueGray-600"}>
        {moment(created_at)?.format("MM/DD/YY")}
      </span>
    );
  };
  const DataCompleted = ({ date_completed }) => {
    return (
      <span className={"ml-3 font-normal " + "text-blueGray-600"}>
        {moment(date_completed)?.format("MM/DD/YY")}
      </span>
    );
  };

  const projectUsersDropdown = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={userUnique}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select One"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };

  const rowExpansionTemplate = (data) => {
    const onRowClick = (e) => {
        const {
          jid,
          status,
          pid
        } = e
          if (status * 100 > 0) {
            history.push(`/admin/tables/${pid}/${jid}`);
          } else {
            CustomToast(
              "Feel free to return later as the data is currently in the process of being analyzed."
            );
          }
       
    
      };
    return (
      <DataTable
      onRowClick={(e) => onRowClick(e?.data)}
        header={true}
        value={data?.jobs?.slice(1, data?.jobs?.length)?.map((node) => {
          return {
            ...node,
            pid: data?.pid,
          };
        })}
        className="project-nested-table"
        tableStyle={{ minWidth: "70rem" }}
      >
        <Column field="jid" sortable />
        <Column field="statusText" sortable />
        <Column field="status" sortable body={NestedStatus} />
        <Column field="created_at" sortable body={DataStarted} />
        <Column field="date_completed" sortable body={DataCompleted} />
      </DataTable>
    );
  };

  return (
    <div className="main z-index-class relative">
      <h2 className="absolute class_heading">{heading}</h2>
      {subHeading && (
        <h3 className="absolute class_sub_heading">{subHeading}</h3>
      )}
      <DataTable
      loading={loading}
        filters={filters}
        filterDisplay="row"
        globalFilterFields={["name"]}
        ref={dt}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        onRowExpand={onRowExpand}
        onRowCollapse={onRowCollapse}
        onRowClick={(e) => onRowClickParent(e)}
        rowExpansionTemplate={rowExpansionTemplate}
        paginator={true}
        rowsPerPageOptions={[5, 10, 25, 50]}
        rows={10}
        value={data}
        tableStyle={{ minWidth: "50rem" }}
      >
        <Column expander={allowExpansion} style={{ width: "24px" }} />
        <Column
          header="Project Name"
          field="name"
          sortable
          filter
          filterPlaceholder="Search by name"
          showFilterMenu={false}
        />
        <Column
          header="Domain Name"
          field="domain"
          sortable
          body={DomaineName}
          filter
          filterPlaceholder="Search by name"
          showFilterMenu={false}
        />
        <Column
          header="Number Of Keywords"
          field="number_of_keywords"
          sortable
        />
        <Column
          header="Project User"
          field="full_name"
          sortable
          body={ProfileUser}
          showFilterMenu={false}
          filter
          filterElement={projectUsersDropdown}
        />
        <Column
          header="statusText"
          field="statusText"
          sortable
          body={StatusText}
        />
        <Column
          header="Data Started MM/DD/YY"
          field="created_at"
          sortable
          body={DataStarted}
        />
        <Column
          header="Data Completed MM/DD/YY"
          field="date_completed"
          sortable
          body={DataCompleted}
        />
      </DataTable>
    </div>
  );
};

export default ProjectsTable;
