import React, { useEffect } from "react";
import "assets/styles/guageChart.scss";
import GaugeComponent from "react-gauge-component";
import { useMediaPredicate } from "react-media-hook";
import { Tooltip } from "primereact/tooltip";
import tooltipIcon from "../../assets/img/tooltip-icon.png";

import clsx from "clsx";
// components

export default function GuageChart({ subheadingClass, threat_rating,hasData,tooltip,classes }) {
  const desktopView = useMediaPredicate("(min-width: 1024px)");
  const largeView = useMediaPredicate("(min-width: 1280px)");
  return (
    <>
      <div className="relative flex flex-col min-w-full h-full bg-white w-full shadow-lg rounded px-4 py-4 guage_chart_outer ">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative flex-grow flex-1  w-fit">
              <div className=" w-fit">
   {/* {tooltip &&   <p className="tooltip_main_para_card_chart">{tooltip}</p>} */}

              <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold w-fit">
                Threats
                {" "}
                <div className="inline-tooltip-img main_container_headers_2">
                  <img
                    src={tooltipIcon}
                    className="inline-tooltip-img w-5 h-5"
                  />
                  {tooltip && <p className="tooltip_main_para_card_chart">{tooltip}</p>}
                </div>
              </h6>
              <h2 className={clsx(subheadingClass,"w-fit")}
            
              >AI Overviews Threat Rating</h2>
              </div>
            </div>
          </div>
        </div>


        {
        hasData?(   
        <div className="relative w-full h-full">
          <div className={desktopView ? "w-full h-full" : ""}>
          <GaugeComponent
            value={threat_rating}
            className="guageChartContainer"
            type="radial"
            labels={{
              tickLabels: {
                type: "inner",
                ticks: [
                  { value: 20 },
                  { value: 40 },
                  { value: 60 },
                  { value: 80 },
                  { value: 100 },
                ],
                defaultTickValueConfig: {
                  style: {
                    fontSize: desktopView ? "18px" : "15px",
                    fill: "#000000",
                    fontWeight: 700,
                  },
                },
              },
            }}
            arc={{
              colorArray: [
                "#00B151",
                "#0095D9",
                "#F7D263",
                "#EF611F",
                "#C91F1F",
              ],
              subArcs: [
                {
                  length: 0.2,
                  tooltip: {
                    text: "Low",
                  },
                },
                {
                  length: 0.2,
                  tooltip: {
                    text: "Guarded",
                  },
                },
                {
                  length: 0.2,
                  tooltip: {
                    text: "Elevated",
                  },
                },
                {
                  length: 0.2,
                  tooltip: {
                    text: "High",
                  },
                },
                {
                  length: 0.2,
                  tooltip: {
                    text: "Severe",
                  },
                },
              ],
              padding: 0.02,
              width: 0.3,
            }}
            pointer={{
              elastic: true,
              animationDelay: 0,
            }}
          />
        </div>
        <div className="backgound_overlay_color absolute w-full h-full flex items-center justify-center top-0 left-0">

        {hasData && <p className="font-semibold text-2xl leading-6 text-black">No Snapshot Found</p>} 
        </div>
        </div>
        ) :(
          <div className={desktopView ? "w-full h-full" : ""}>
          <GaugeComponent
            value={threat_rating}
            className="guageChartContainer"
            type="radial"
            labels={{
              tickLabels: {
                type: "inner",
                ticks: [
                  { value: 20 },
                  { value: 40 },
                  { value: 60 },
                  { value: 80 },
                  { value: 100 },
                ],
                defaultTickValueConfig: {
                  style: {
                    fontSize: desktopView ? "18px" : "15px",
                    fill: "#000000",
                    fontWeight: 700,
                  },
                },
              },
            }}
            arc={{
              colorArray: [
                "#00B151",
                "#0095D9",
                "#F7D263",
                "#EF611F",
                "#C91F1F",
              ],
              subArcs: [
                {
                  length: 0.2,
                  tooltip: {
                    text: "Low",
                  },
                },
                {
                  length: 0.2,
                  tooltip: {
                    text: "Guarded",
                  },
                },
                {
                  length: 0.2,
                  tooltip: {
                    text: "Elevated",
                  },
                },
                {
                  length: 0.2,
                  tooltip: {
                    text: "High",
                  },
                },
                {
                  length: 0.2,
                  tooltip: {
                    text: "Severe",
                  },
                },
              ],
              padding: 0.02,
              width: 0.3,
            }}
            pointer={{
              elastic: true,
              animationDelay: 0,
            }}
          />
        </div>
        )
      }

      
      </div>
    </>
  );
}
