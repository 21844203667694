import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import "assets/styles/adminView.scss";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Tables from "views/admin/Tables.js";
import CreatView from "views/admin/CreateView";
import ProtectedRoute from "components/ProtectedRoute";
import clsx from "clsx";
import PostHTML from "components/PostHtml";
import ContactForm from "components/Forms/ContactForm";
import { hideBodyScroll, showBodyScroll } from "utils/helpers";

export default function Admin() {
  const [loading, setLoading] = useState(true);
  const [toggleForm, setToggleForm] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setLoading(true);
  }, [location]);

  const toggleFunc = () => {
    setToggleForm(!toggleForm);
  };
  useEffect(()=>{
    if(toggleForm){
      hideBodyScroll()
    }
    else{
      showBodyScroll()
    }

  },[toggleForm])
console.log(toggleForm,"toggleForm");

return (
    <>
      <ProtectedRoute>
        <Sidebar />
        <div
          className={clsx(
            loading ? "bg-blueGray-50" : "bg-blueGray-100",
            "relative md:ml-64  min-h-screen flex flex-col justify-between"
          )}
        >
          <AdminNavbar />
          <div className="mx-auto w-full relative z-index-root-class ">
            <Switch>
            <Route path="/admin/tables/:id/:jId" exact>
                <ProtectedRoute>
                  <Dashboard  setLoading={setLoading} loading={loading} toggleForm={toggleFunc} />
                </ProtectedRoute>
              </Route>

              <Route path="/admin/tables/:id" exact>
                <ProtectedRoute>
                  <Dashboard setLoading={setLoading} loading={loading} toggleForm={toggleFunc} />
                </ProtectedRoute>
              </Route>

              <Route path="/admin/" exact>
                <ProtectedRoute>
                  <Tables />
                </ProtectedRoute>
              </Route>

              <Route path="/admin/creatview" exact>
                <ProtectedRoute>
                  <CreatView />
                </ProtectedRoute>
              </Route>

              <Redirect from="/admin" to="/admin/" />
            </Switch>
          </div>
          <FooterAdmin />
        </div>

        <div
        className={clsx(
          'fixed top-0 right-0 flex justify-end h-full z-parent-div overflow-hidden',
          toggleForm ? 'w-full' : 'w-0'
        )}
      >
        <button
          aria-label="button"
          onClick={() => toggleFunc()}
          className="w-full h-full absolute left-0 top-0 form-overlay"
        ></button>
        <ContactForm
          toggleCancel={toggleFunc}
          otherClasses={clsx(
            'z-10 transition-width ',
            'w-full'
          )}
        />
      </div>

      </ProtectedRoute>
    </>
  );
}
