import { createContext } from "react";
import Firebase from "./firebase";

const FirebaseContext = createContext(new Firebase());

FirebaseContext.displayName = "FirebaseContext";

const FirebaseProvider = FirebaseContext.Provider;

export { FirebaseProvider, FirebaseContext };
