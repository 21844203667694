import React, { useContext } from "react";
import "assets/styles/adminnavbar.scss";
import UserDropdown from "components/Dropdowns/UserDropdown.js";
import { SessionContext } from "components/Context";
import { getNameInitials } from "utils/helpers";

export default function Navbar() {
  const { user,projectName } = useContext(SessionContext);
  const { displayName, photoURL } = user || {};

  const initals = getNameInitials(displayName);

  return (
    <>
      <nav className="fixed admin_desktop_navbar bg-lightBlue-600 top-0 right-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
        <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
        <p className="text-white text-sm uppercase hidden lg:inline-block font-semibold">
           {projectName?projectName:"Dashboard"}
          </p>
          
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <UserDropdown photoURL={photoURL} initals={initals} />
          </ul>
        </div>
      </nav>
    </>
  );
}
