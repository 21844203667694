import ModalComponent from "components/ModalComponent/ModalComponent";
import React from "react";

const DeleteKeyword = ({ handleClose, onSubmit, show, modalData, title }) => {
  return (
    <ModalComponent handleClose={handleClose} show={show}>
      <form onSubmit={(e) => onSubmit(e, modalData)} className="w-full">
        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Delete Keywords
        </h6>
        <p className="text-black text-sm mt-3 mb-6 font-bold uppercase text-center">
          Are you sure?
          <br />
          You want to Delete Keyword {title}
        </p>

        <div className="flex items-center justify-center w-full mt-8">
          <button
            type="submit"
            className="px-20 py-4 w-full  rounded-lg button_class_delete flex items-center justify-center gap-4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512"
            >
              <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
            </svg>
            Submit
          </button>
        </div>
      </form>
    </ModalComponent>
  );
};

export default DeleteKeyword;
