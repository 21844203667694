import React, { useEffect } from 'react'
import clsx from 'clsx'

import 'assets/styles/contactform.scss'

export default function ContactForm({otherClasses,toggleCancel}) {

  const contactFormClasses = clsx(
    otherClasses,
    'max-w-form bg-white overflow-y-auto h-full'
  )
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js-eu1.hsforms.net/forms/embed/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          target: '#hubspotForm',
          region: "na1",
            portalId: "738796",
            formId: "52c7c2c2-e714-43ba-8fb2-fc136318905b",
        })
      }
    })
  }, [])


  return (
    <div className={contactFormClasses} data-testid="contact-form">
      <div className="flex justify-between items-center px-6 py-4 parent-bg-color">
     
        <button className='close_button_color' onClick={() => toggleCancel()}>
        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
        </button>
        <a className='font-semibold text-black text-lg '  href="mailto:mike@ipullrank.com">mike@ipullrank.com</a>
      </div>
      <div className="pb-6 px-6  lg:px-10 w-full  pt-12 flex flex-col gap-10">
       
       <div id="hubspotForm"></div>
      </div>
    </div>
    
  );
}


