import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/index.css";
import "font-awesome/css/font-awesome.min.css";
import "react-loading-skeleton/dist/skeleton.css";
// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import { SessionContextProvider } from "components/Context";
import UnProtectedRoute from "components/UnProtectedRoute";
import PublicView from "components/PublicView";
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';



ReactDOM.render(
  
  <SessionContextProvider>
    <BrowserRouter>
      <Switch>
      <Route path="/share/:id/:sid">
           <PublicView/>
        </Route>
        <Route path="/admin" component={Admin} />
        <Route path="/">
          <UnProtectedRoute>
            <Auth />
          </UnProtectedRoute>
        </Route>
        
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
    <ToastContainer closeButton={true} />
  </SessionContextProvider>,
  document.getElementById("root")
);
