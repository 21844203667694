import React, { useEffect } from "react";
import clsx from "clsx";
import { hideBodyScroll, showBodyScroll } from "utils/helpers.js";

export const ModalComponent = ({
  otherClasses,
  show,
  handleClose,
  additionalClass,
  children,
}) => {
  const modalComponentClasses = clsx(
    "flex justify-center fixed top-0 left-0 bottom-0 right-0 transition duration-300 overflow-auto",
    show ? "modal_show" : " not_modal_show",
    additionalClass
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEscapeClick, false);
    return () => {
      document.removeEventListener("keydown", handleEscapeClick, false);
    };
    // eslint-disable-next-line
  }, []);

  const handleEscapeClick = (event) => {
    if (event.keyCode === 27) handleClose();
  };

  if (show) {
    hideBodyScroll();
  } else {
    showBodyScroll();
  }

  return (
    <div className={modalComponentClasses} data-testid="modal-component">
      <button
        aria-label="button"
        className={
          "modal-overlay absolute left-0 top-0 bottom-0 right-0 z-[-1] cursor-auto w-full h-full"
        }
        onClick={() => handleClose()}
      />
      <div className={clsx("modal-wrapper", otherClasses)}>
        <button onClick={() => handleClose()} className="button_close">
          <i className="fas fa-times"></i>
        </button>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default ModalComponent;
