import React, { useContext, useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
// components

import CardPageVisits from "components/Cards/CardPageVisits.js";
import GuageChart from "components/Cards/GuageChart.jsx";
import PieChart from "components/Cards/PieChart.jsx";
import "assets/styles/dashboard.scss";
import Histogram from "components/Cards/Histogram";
import HeaderStats from "components/Headers/HeaderStats.js";
import { useParams, useHistory } from "react-router-dom";
import CtaSection from "components/reports/CtaSection";
import { SessionContext } from "components/Context";
import DashboardSkeleton from "./Dashboard.skeleton";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  CustomToast,
  average,
  getLoadTimeData,
  getPieChartData,
} from "utils/helpers";
import moment from "moment";
import { useFirebase } from "components/Firebase";
import clsx from "clsx";
import LinksComponent from "components/LinksComponent";

const SHOWN_COLOR_CHART = ["#4472C4", "#ED7D31", "#A5A5A5", "#FFC000"];

const LOCAL_CHARTS_COLORS = ["#FFC000", "#A5A5A5", "#ED7D31", "#4472C4"];
const DEMO_CHART_PIE =
  [
    {
      "key": "HAS_SNAPSHOT",
      "value": 12
    },
    {
      "key": "NO_SNAPSHOT",
      "value": 4
    }
  ]

  const snapshot_type = [
    {
      0: "None",
    },
    {
      1: "Informational",
    },
    {
      2: "Shopping",
    },
    {
      3: "Local",
    },
  ];

export default function Dashboard({ loading, setLoading, toggleForm }) {
  const { setProjectName } = useContext(SessionContext);
  const { getCurrentUserAccessToken } = useFirebase();
  const [response, setResponse] = useState({});
  const history = useHistory();
  const [charts, setCharts] = useState({});
  const { id, jId } = useParams();
  const [retry, setRetry] = useState(false);


  const getProjectById = async () => {
    const accessToken = await getCurrentUserAccessToken();
    if (accessToken) {
      if (!jId || jId === undefined || jId === null) {
        try {
          const {
            data: { data },
          } = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/projects/${id}`,
            {
              headers: {
                Authorization: accessToken,
              },
            }
          );
          const {project:{keywords}} = data ||{};

           const response = data
               const combinedData = response?.data?.data?.keyword
           ?.map((keyword, i) => ({
             Position:  !isNaN(parseFloat(response?.data?.data?.Position[i])) && isFinite(response?.data?.data?.Position[i]) ? Math?.ceil(response?.data?.data?.Position[i])?.toLocaleString(): response?.data?.data?.Position[i],
             historical_Traffic: response?.data?.data?.historical_Traffic[i],
             keyword,
             id: keywords?.find((node) => node?.keyword === keyword)?.id,
             status: keywords?.find((node) => node?.keyword === keyword)?.status,
             mention_in_snapshot: response?.data?.data?.mention_in_snapshot[i],
             snapshot_shopping_local:
               snapshot_type[response?.data?.data?.snapshot_shopping_local[i]][
                 response?.data?.data?.snapshot_shopping_local[i]
               ],
             traffic_Loss: response?.data?.data?.traffic_Loss[i],
             defaultExpanded: false,
           }))
           ?.sort((a, b) => a.keyword.localeCompare(b.keyword));

           const descOrder = combinedData.sort((a, b) => b.traffic_Loss - a.traffic_Loss)

          setResponse((prev)=>{
            return {
              ...prev,
              ...data,
              tableData:descOrder,
              defaultDataTable:descOrder
            }
          });
        } catch (error) {
          const {
            response: {
              data,
            },
          } = error || {};

          const { error: err } = data || {}
          CustomToast(err || data || "Unexpected");
          console.log(error);
          console.log(accessToken);
          history.push("/admin/");
        }
      }
      else {
        try {
          const {
            data: { data },
          } = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/projects/${id}/job/${jId}`,
            {
              headers: {
                Authorization: accessToken,
              },
            }
          );
          setResponse((prev)=>{
            return {
              ...prev,
              ...data
            }
          });
        } catch (error) {
          const {
            response: {
              data,
            },
          } = error || {};

          const { error: err } = data || {}
          CustomToast(err || data || "Unexpected");
          console.log(error);
          history.push("/admin/");
        }
      }
    }
  };


  const getProjectLinks = async () => {
    const accessToken = await getCurrentUserAccessToken();
    if (accessToken) {
    
        try {
          const {
            data: { links },
          } = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/projects/${id}/share`,
            {
              headers: {
                Authorization: accessToken,
              },
            }
          );
          setResponse((prev)=>{
            return{
              ...prev,
              links
            }
          });
        } catch (error) {
          const {
            response: {
              data,
            },
          } = error || {};

          const { error: err } = data || {}
          CustomToast(err || data || "Unexpected");
          console.log(error);
        }
    }
  };

  const retryProjectFailed = async (e) => {
    e.preventDefault();
    const accessToken = await getCurrentUserAccessToken();
    if (accessToken) {
      setRetry(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/projects/${id}/retryFailed`, null, {
          headers: {
            'Authorization': accessToken,
          },
        });
        if (response?.status === 200) {
          CustomToast("Successfully retry the project scrapping visit after some time for updated data")
          setRetry(false)
        }
      } catch (error) {
        const {
          response: {
            data,
          },
        } = error || {};

        const { error: err } = data || {}
        CustomToast(err || data || "Unexpected");
        setRetry(false)
      } finally {
        setRetry(false)
      }

    }
  };



  useEffect(() => {

    getProjectById();
    getProjectLinks();
    const intervalId = setInterval(() => {
      if ((response?.data?.number_of_keywords_processed / response?.data?.keyword_count) * 100 < 100) {
        getProjectById();
        getProjectLinks();
      }
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, [id]);


  const { project: { keywords = [], created_at = "", domain = "" } = {}, data: { number_of_keywords_processed = 0, keyword_count = 0 } = {} } =
    response || {};
  const statusKeywords = keywords?.filter(({ status }) => status !== null || status !== "FAILED");
  const noOfRetries = keywords?.map(({ retries }) => retries);
  const statusOfProject = (number_of_keywords_processed / keyword_count) * 100;
  const statuses = keywords?.filter(({ status }) => status);


  useEffect(() => {
    if (!response || !response.data) {
      setLoading(true);
      return;
    }

    if (response) {
      setLoading(false);
    }
    const {
      data: {
        show_or_not_shown = [],
        snapshot_local_chart = [],
        carousel_positions,
        top_ten_organic_positions,
      } = {},
    } = response || {};

    if (response?.project?.name) {
      setProjectName(response?.project?.name);
    }
    if (show_or_not_shown?.length === 0) {
      const response = getPieChartData(DEMO_CHART_PIE, SHOWN_COLOR_CHART);

      setCharts((prev) => {
        return {
          ...prev,
          shown: response,
        };
      });
    }

    if (Array.isArray(show_or_not_shown) && show_or_not_shown?.length > 0) {
      let lastClicked = null;
      const handleClick = (e) => {
        const clicked = e;
        console.log("hello-world",clicked);
        if (clicked === lastClicked) {
           // Set tableData to an empty array
           setResponse((prev) => ({
             ...prev,
             tableData: response?.defaultDataTable
           }));
        } else {
           // If a different string is clicked, filter the data
           const filteredData = response.tableData.filter((node) => node?.status === clicked);
       
           // Update the response with the filtered data
           setResponse((prev) => ({
             ...prev,
             tableData: filteredData
           }));
       
           // Update lastClicked with the current clicked string
           lastClicked = clicked;
        }
       };
      const chartRes = getPieChartData(show_or_not_shown, SHOWN_COLOR_CHART,handleClick);

      setCharts((prev) => {
        return {
          ...prev,
          shown: chartRes,
        };
      });
    }
    if (snapshot_local_chart?.length === 0) {
      const response = getPieChartData(DEMO_CHART_PIE, SHOWN_COLOR_CHART);

      setCharts((prev) => {
        return {
          ...prev,
          local_charts: response,
        };
      });
    }
    if (
      Array.isArray(snapshot_local_chart) &&
      snapshot_local_chart?.length > 0
    ) {
      let lastClickedSnap = null;
      const handleClick = (e) => {
        if (e === lastClickedSnap) {
           setResponse((prev) => ({
             ...prev,
             tableData: response?.defaultDataTable
           }));
        } else {
           const filteredbySnapshotData = response?.defaultDataTable?.filter((node) => node?.snapshot_shopping_local === e);
           setResponse((prev) => ({
             ...prev,
             tableData: filteredbySnapshotData
           }));
       
           lastClickedSnap = e;
        }
       };
      const responseSnaped = getPieChartData(
        snapshot_local_chart,
        LOCAL_CHARTS_COLORS,
        handleClick,
      );

      setCharts((prev) => {
        return {
          ...prev,
          local_charts: responseSnaped,
        };
      });
    }
    if (Array.isArray(statusKeywords) && statusKeywords?.length > 0) {
      const response = getLoadTimeData(statusKeywords);

      setCharts((prev) => {
        return {
          ...prev,
          load_time: response,
        };
      });
    }
    if (carousel_positions !== null || carousel_positions !== undefined) {
      let countArray = [0, 1, 2, 3];
      let positionArray = [1, 2, 4, 3];
      let avg = 0;
      if (JSON.stringify(carousel_positions["Count"]) !== "{}") {
        countArray = Object.values(carousel_positions["Count"])?.map(Number);
      }
      if (JSON.stringify(carousel_positions["Position"]) !== "{}") {
        positionArray = Object.values(carousel_positions["Position"])?.map(Number);
      }
      if (positionArray?.length > 0) {
        avg = average(positionArray);
      }

      const resp = countArray.map((node, i) => ({
        dataPointIndexParent: i,
        count: node,
        position: positionArray[i],
        topLabel: "Carousel Position",
        bgClass: avg === node ? "rounded-icon-blue" : "rounded-icon-red",
        title: avg === node ? "Average Count" : "Count",
        secTitle: avg === node ? "Average Position" : "Position",
      }));


      const histogram = {
        series: [
          {
            name: "Positions",
            data: countArray
          },
        ],

        options: {
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: "100%"
                },
              }
            },
            {
              breakpoint: 720,
              options: {
                chart: {
                  width: "100%"
                },
              }
            },
            {
              breakpoint: 1024,
              options: {
                chart: {
                  width: "100%"
                },
              }
            }
          ],
          plotOptions: {
            bar: {
              strokeWidth: 2,
              borderRadius: 5,
              borderRadiusApplication: "end",
            },
          },
          fill: {
            opacity: 0.3,
            colors: [function ({ value }) {
              if (value === avg) {
                return "rgba(32, 120, 255, 0.4)"
              } else {
                return "#ff4040"
              }
            }],
          },
          stroke: {
            width: 2,
            colors: ["rgba(0, 0, 0, 0.3)"],
            opacity: 0.3
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          xaxis: {
            labels: {
              rotate: 0,
            },
            title: {
              text: "Position",
            },
            categories: positionArray,
            tickPlacement: "on",
            axisBorder: {
              show: true,
              color: "#000000",
              offsetX: -5,
              offsetY: 0,
            },
          },

          dataLabels: {
            enabled: false,
            style: {
              fontSize: "12px",
              fontWeight: "bold",
              colors: ["#000000"],
            },
          },
          yaxis: {
            title: {
              text: "Count",
            },

            axisBorder: {
              show: true,
              offsetX: -5,
              offsetY: 0,
              color: "#000000",
            },
          },
          tooltip: {
            onDatasetHover: {
              highlightDataSeries: true,
            },
            custom: function ({ seriesIndex, dataPointIndex, w }) {
              // var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
              const { title, position, secTitle,
                count, topLabel, bgClass } = resp?.find(({ dataPointIndexParent }) => dataPointIndexParent === dataPointIndex);
              return renderToString(
                <div className="flex flex-col gap-2 shadow-lg rounded-tooltip">
                  <div className="top_header px-2 py-2">{topLabel}</div>
                  <div className="px-2 py-2 justify-center items-center flex gap-2 bottom_text">
                    <div className="flex flex-col gap-2">

                      <div className=" flex gap-2 items-center">
                        <div className={bgClass}></div>
                        <p>{title}: </p>
                        <p>{count}</p>
                      </div>
                      <div className="flex gap-2 items-center">
                        <div className={bgClass}></div>
                        <p>{secTitle}: </p>
                        <p>{position}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            },
          },
        },
      };

      setCharts((prev) => {
        return {
          ...prev,
          carousel_Position: histogram,
        };
      });
    }
    if (
      top_ten_organic_positions !== null ||
      top_ten_organic_positions !== undefined
    ) {
      let countArray = [0, 1, 2, 3];
      let positionArray = [1, 2, 4, 3];
      let avg = 0;
      if (JSON.stringify(top_ten_organic_positions["Count"]) !== "{}") {
        countArray = Object?.values(top_ten_organic_positions["Count"])?.map(
          Number
        );
      }
      if (JSON.stringify(top_ten_organic_positions["Position"]) !== "{}") {
        positionArray = Object?.values(
          top_ten_organic_positions["Position"]
        )?.map((node) => node + 1);
      }
      if (positionArray?.length > 0) {
        avg = average(positionArray);
      }


      const resp = countArray.map((node, i) => ({
        dataPointIndexParent: i,
        count: node,
        position: positionArray[i],
        topLabel: "Organic Ranking",
        bgClass: avg === node ? "rounded-icon-blue" : "rounded-icon-red",
        title: avg === node ? "Average Count" : "Count",
        secTitle: avg === node ? "Average Position" : "Position",
      }));


      const histogram = {
        series: [
          {
            name: "Positions",
            data: countArray
          },
        ],
        options: {
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: "100%"
                },
              }
            },
            {
              breakpoint: 720,
              options: {
                chart: {
                  width: "100%"
                },
              }
            },
            {
              breakpoint: 1024,
              options: {
                chart: {
                  width: "100%"
                },
              }
            }
          ],
          plotOptions: {
            bar: {
              strokeWidth: 2,
              borderRadius: 5,
              borderRadiusApplication: "end",
            },
          },
          fill: {
            opacity: 0.3,
            colors: [function ({ value }) {
              if (value === avg) {
                return "rgba(32, 120, 255, 0.4)"
              } else {
                return "#ff4040"
              }
            }],
          },
          stroke: {
            width: 2,
            colors: ["rgba(0, 0, 0, 0.3)"],
            opacity: 0.3
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            labels: {
              rotate: -45,
            },
            title: {
              text: "Position",
            },
            categories: positionArray,
            tickPlacement: "on",
            axisBorder: {
              color: "#000000",
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "12px",
              fontWeight: "bold",
              colors: ["#000000"],
            },
          },
          yaxis: {
            title: {
              text: "Count",
            },

            axisBorder: {
              show: true,
              color: "#000000",
            },
          },
          tooltip: {
            onDatasetHover: {
              highlightDataSeries: true,
            },
            custom: function ({ seriesIndex, dataPointIndex, w }) {
              // var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
              const { title, position, secTitle,
                count, topLabel, bgClass } = resp?.find(({ dataPointIndexParent }) => dataPointIndexParent === dataPointIndex);
     
              return renderToString(
                <div className="flex flex-col gap-2 shadow-lg rounded-tooltip">
                  <div className="top_header px-2 py-2">{topLabel}</div>
                  <div className="px-2 py-2 justify-center items-center flex gap-2 bottom_text">
                    <div className="flex flex-col gap-2">

                      <div className=" flex gap-2 items-center">
                        <div className={bgClass}></div>
                        <p>{title}: </p>
                        <p>{count}</p>
                      </div>
                      <div className="flex gap-2 items-center">
                        <div className={bgClass}></div>
                        <p>{secTitle}: </p>
                        <p>{position}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            },
          },
        },
      };

      setCharts((prev) => {
        return {
          ...prev,
          organic_positions: histogram,
        };
      });
    }
  }, [response]);


  console.log(charts);
  console.log(response);
  console.log({ id, jId }, "id");

  return (
    <>
      {loading ? (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title> Projects Report | Serpentor</title>
            <meta name="description" content="Project listings for Serpentor" />
          </Helmet>
          <DashboardSkeleton />
        </>
      ) : (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              SGE Threat Report for {domain} by iPullRank - {moment(created_at).format("MM/DD/YY")} Data
            </title>
            <meta
              name="description"
              content={`iPullRank SGE analysis for ${domain} from ${moment(
                created_at
              ).format("MM/DD/YY")}`}
            />
          </Helmet>
          <HeaderStats
            getProjectById={getProjectById}
            setResponse={setResponse}
            notes={response?.project?.notes || []}
            traffic_Loss={response?.data?.aggregate_traffic_loss || ""}
            status={statusOfProject || 0}
            crawl_type={response?.project?.schedule || ""}
            name={response?.project?.name || ""}
            no_of_keywords_crawled_remaing={
              `${number_of_keywords_processed}/${keyword_count}` || ""
            }
          />
          <div className="relative dashaboard_class_top">
            {(statuses?.some((node) => node === "FAILED")) && (!jId || jId === undefined || jId === null) && <div className="flex justify-end retry_container mt-4">
              <button
                type="button"
                onClick={(e) => retryProjectFailed(e)}
                className=
                {clsx("text-lg leading-6 font-medium flex gap-2 items-center ",
                  retry ? "button_retry" : "button_retry_default"
                )}

              >
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z" /></svg>
                Retry
              </button>
            </div>}
            <div className="dashbaord-charts-container mb-8">

              <GuageChart
                hasData={response?.data?.threat_rating === null}
                threat_rating={response?.data?.threat_rating || 0}
                subheadingClass="subhaeding_charts"
                tooltip="The risk level of traffic loss for your website based on the presence of the AI Snapshot for the keywords that currently drive traffic. The different colors indicate severity from low (green) to high (red)."
                classes="sge-threat-rating"
              />
              <PieChart
                chartData={charts?.shown}
                heading="AI SnapShot"
                hasData={
                  Array.isArray(response?.data?.show_or_not_shown) && response?.data?.show_or_not_shown?.length === 0
                }
                subHeading="Shown/Not Shown"
                subheadingClass="subhaeding_charts"
                classes="shown-not-shown"
                tooltip="The percentage of keywords where the AI Snapshot appeared versus did not appear."
              />
              <PieChart
                heading="AI SnapShot"
                subHeading="Snapshot Type"
                hasData={
                  Array.isArray(response?.data?.snapshot_local_chart) && response?.data?.snapshot_local_chart?.length === 0
                }
                chartData={charts?.local_charts}
                subheadingClass="subhaeding_charts"
                tooltip="Percentage distribution of AI Snapshot types, categorized into Informational, Shopping, and Local. Valuable due to the different AI Overview layouts of these categories."
                classes="type-snapshot"
              />
              <Histogram
              tooltip="The time it takes (in sections) for the AI Snapshot to load. The longer the load time, the less threatening the AI Snapshot is estimated to be for your keywords."
                name="Load Time"
                heading="AI SnapShot"
                hasData={
                  Array.isArray(statusKeywords) && statusKeywords?.length === 0
                }
                subHeading="Load Times"
                subheadingClass="subhaeding_charts"
                chartData={charts?.load_time}
              />
              <Histogram
              tooltip="The number of times your domain was featured in the AI Snapshot carousel. "
                name="Domain Featured"
                hasData={
                  JSON.stringify(
                    response?.data?.carousel_positions["Count"]
                  ) === "{}"
                }
                heading="Carousel Positions"
                subHeading="Domain Featured"
                subheadingClass="subhaeding_charts"
                chartData={charts?.carousel_Position}
              />
              <Histogram
              tooltip="The current top 10 organic ranking of your website for the crawled keywords."
                name="Ranking"
                heading="Top 10 Ranking"
                subHeading="Ranking"
                hasData={
                  JSON.stringify(
                    response?.data?.top_ten_organic_positions["Count"]
                  ) === "{}"
                }
                subheadingClass="subhaeding_charts"
                chartData={charts?.organic_positions}
              />
            </div>

            <div className="flex flex-wrap container_page_visit_stats mt-4">
              <div className="w-full  ">
                <CardPageVisits
                name={response?.project?.name}
                  isPublic={true}
                  keywords={keywords || []}
                  tableData={response?.tableData || []}
                  retires={noOfRetries?.reduce(
                    (partialSum, value) => partialSum + value,
                    0
                  )}
                />
              </div>
            </div>
            <div className=" container_report_cta mt-4 mb-12 xl:mb-0">
              <div className="bg-white w-full shadow-lg py-4">
                <LinksComponent nodes={response?.links} setResponse={setResponse} />
              </div>
            </div>
            <div className=" container_report_cta mt-4 mb-12 xl:mb-0">
              <div className="bg-white w-full shadow-lg py-4">
                <CtaSection toggleForm={toggleForm} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
