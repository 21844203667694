import React, { useEffect, useState } from "react";
import clsx from "clsx";
import "assets/styles/projectView.scss";
import { useFirebase } from "components/Firebase";
import SelectDropdown from "components/Dropdowns/SelectDropdown.jsx";
import KeywordTable from "components/Tables/KeywordTable";
import FileInput from "components/FileInput";
import { CustomToast, handleFormValidation, parseCSV } from "utils/helpers";
import axios from "axios";


const DATA_SOURCE = [
  {
    title: "Semrush",
    value: "semrush",
  },
  {
    title: "Google and semrush",
    value: "gsc_and_semrush",
  },
];

const SCHEDULE = [
  {
    title: "One time",
    value: "0",
  },
  {
    title: "Daily",
    value: "1",
  },
  {
    title: "Weekly",
    value: "2",
  },
  {
    title: "Monthly",
    value: "3",
  },
];

const MODAL_TYPE = [
  {
    title: "Predefined Model",
    value: "param",
  },
  {
    title: "Custom Model",
    value: "file",
  },
];

const INITIAL_STATE = {
  modalType: null,
  semrush: null,
  gsc: null,
  keywords: [],
  isSubmitEnabled: true,
  schedule: "0",
  modalType: "param",
  dataSource: "semrush"
};

const ProjectView = () => {
  const [state, setState] = useState(INITIAL_STATE);
  const { getCurrentUserAccessToken } = useFirebase();
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (state?.modalType) {
      if (state?.modalType === "Predefined Model") {
        setState((prev) => {
          return {
            ...prev,
            custom_CTR: null,
          };
        });
      } else {
        setState((prev) => {
          return {
            ...prev,
            predefined_CTR: null,
          };
        });
      }
    }
  }, [state?.modalType]);

  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      setTimeout(() => {
        setFormErrors({});
      }, 2000);
    }
  }, [formErrors]);

  useEffect(() => {
    if (state?.KEYWORDS) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        let keywordArray;
        const isCSV = state.KEYWORDS.name.toLowerCase().endsWith(".csv");
        if (isCSV) {
          keywordArray = parseCSV(text);
        } else {
          keywordArray = text.split(/[,|\n]/).map((keyword) => keyword.trim());
        }
        setState((prev) => ({
          ...prev,
          keywords: keywordArray,
        }));
      };
      reader.readAsText(state.KEYWORDS);
    }
  }, [state?.KEYWORDS]);

  const handleChange = (e) => {
    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const onSelectChange = (e) => {
    setState((prev) => {
      return {
        ...prev,
        [e.target.getAttribute("itemID")]: e.target.getAttribute("data-name"),
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!state?.isSubmitEnabled) {
      if (!loading) {
        const { error } = handleFormValidation(state);
        console.log(error);
        if (error) {
          setFormErrors({ ...error });
        }
        if (!error) {
          try {
            const {
              domain,
              name,
              keywords,
              schedule,
              modalType,
              predefined_CTR,
              custom_CTR,
              semrush,
              gsc,
            } = state || {};
            setLoading(true);
            const formData = new FormData();

            formData.append("domain", domain);
            formData.append("name", name);
            formData.append(`keywords`, keywords.join(","));
            formData.append("schedule", schedule);
            formData.append("ctr_type", modalType);
            modalType === "param" &&
              formData.append("ctr_category", predefined_CTR);
            modalType === "file" && formData.append("ctr", custom_CTR);
            formData.append("semrush", semrush);
            formData.append("gsc", gsc ? gsc : null);
            const accessToken = await getCurrentUserAccessToken();
            const response = await axios.post(
              `${process.env.REACT_APP_BACKEND_URL}/v1/projects/`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: accessToken,
                },
              }
            );
            console.log(response);
            if (response?.status === 200) {
              CustomToast("Successfully created the project");
              setState(INITIAL_STATE);
            }
            setLoading(false);
          } catch (error) {
            const {
              response: {
                data: { errors },
              },
            } = error;
            console.log(errors);
            CustomToast(errors?.messages.toString() || "Something went wrong");
            setLoading(false);
          }
        }
      }
    }
  };

  console.log(state);
  // console.log(Array.isArray(SCHEDULE) && typeof SCHEDULE[0] === "string"
  // ? state?.schedule
  // : SCHEDULE?.find(
  //     ({ value }) => value === state?.schedule
  //   )?.title);
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Create Project
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={(e) => handleSubmit(e)} >
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Project Information
            </h6>
            <div className="flex flex-col gap-4">
              <div className="flex flex-wrap ">
                <div className="w-full lg:w-6/12 px-4 pb-4">
                  <div className="relative w-full">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="domain"
                    >
                      Target Domain
                    </label>
                    <input
                      type="url"
                      name="domain"
                      value={state?.domain || ""}
                      onChange={(e) => handleChange(e)}
                      id="domain"
                      className={clsx(
                        formErrors?.domain
                          ? "primary_error_class "
                          : "outline-0 placeholder-blueGray-300 text-blueGray-600 focus:nooutline shadow  normal-input",
                        "px-3 py-3 border-0   bg-white rounded text-sm   w-full  "
                      )}
                      placeholder="Enter the Target Domain"
                    />
                    {formErrors?.domain && (
                      <div className="absolute icon_abolute_class">
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.00003 1.12109C5.53841 1.12109 5.20166 1.399 4.91355 1.76103C4.63174 2.11513 4.33186 2.63313 3.961 3.27374L3.94464 3.30199L1.91353 6.81027L1.89716 6.83854C1.52489 7.48153 1.22394 8.00133 1.05666 8.4233C0.885707 8.85455 0.812157 9.28615 1.04328 9.68702C1.2744 10.0879 1.68476 10.2405 2.14364 10.3086C2.59263 10.3753 3.19326 10.3752 3.93625 10.3752H3.96892H8.03113H8.06381C8.80679 10.3752 9.40742 10.3753 9.85642 10.3086C10.3153 10.2405 10.7257 10.0879 10.9568 9.68702C11.1879 9.28615 11.1143 8.85456 10.9434 8.42331C10.7761 8.00135 10.4752 7.48156 10.1029 6.8386L10.1029 6.83855L10.0865 6.81027L8.05541 3.302L8.03906 3.27374C7.6682 2.63313 7.36831 2.11513 7.08651 1.76103C6.7984 1.399 6.46165 1.12109 6.00003 1.12109ZM6.37503 4.50046C6.37503 4.29335 6.20713 4.12546 6.00003 4.12546C5.79292 4.12546 5.62503 4.29335 5.62503 4.50046V6.50046C5.62503 6.70757 5.79292 6.87546 6.00003 6.87546C6.20713 6.87546 6.37503 6.70757 6.37503 6.50046V4.50046ZM6.50003 8.00046C6.50003 8.2766 6.27617 8.50046 6.00003 8.50046C5.72388 8.50046 5.50003 8.2766 5.50003 8.00046C5.50003 7.72432 5.72388 7.50046 6.00003 7.50046C6.27617 7.50046 6.50003 7.72432 6.50003 8.00046Z"
                            fill="#F52419"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="name"
                    >
                      Project Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={state?.name || ""}
                      onChange={(e) => handleChange(e)}
                      id="name"
                      className={clsx(
                        formErrors?.name
                          ? "primary_error_class "
                          : "outline-0 placeholder-blueGray-300 text-blueGray-600 focus:nooutline shadow  normal-input",
                        "px-3 py-3 border-0   bg-white rounded text-sm   w-full  "
                      )}
                      placeholder="Enter the Project Name "
                    />
                    {formErrors?.name && (
                      <div className="absolute icon_abolute_class">
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.00003 1.12109C5.53841 1.12109 5.20166 1.399 4.91355 1.76103C4.63174 2.11513 4.33186 2.63313 3.961 3.27374L3.94464 3.30199L1.91353 6.81027L1.89716 6.83854C1.52489 7.48153 1.22394 8.00133 1.05666 8.4233C0.885707 8.85455 0.812157 9.28615 1.04328 9.68702C1.2744 10.0879 1.68476 10.2405 2.14364 10.3086C2.59263 10.3753 3.19326 10.3752 3.93625 10.3752H3.96892H8.03113H8.06381C8.80679 10.3752 9.40742 10.3753 9.85642 10.3086C10.3153 10.2405 10.7257 10.0879 10.9568 9.68702C11.1879 9.28615 11.1143 8.85456 10.9434 8.42331C10.7761 8.00135 10.4752 7.48156 10.1029 6.8386L10.1029 6.83855L10.0865 6.81027L8.05541 3.302L8.03906 3.27374C7.6682 2.63313 7.36831 2.11513 7.08651 1.76103C6.7984 1.399 6.46165 1.12109 6.00003 1.12109ZM6.37503 4.50046C6.37503 4.29335 6.20713 4.12546 6.00003 4.12546C5.79292 4.12546 5.62503 4.29335 5.62503 4.50046V6.50046C5.62503 6.70757 5.79292 6.87546 6.00003 6.87546C6.20713 6.87546 6.37503 6.70757 6.37503 6.50046V4.50046ZM6.50003 8.00046C6.50003 8.2766 6.27617 8.50046 6.00003 8.50046C5.72388 8.50046 5.50003 8.2766 5.50003 8.00046C5.50003 7.72432 5.72388 7.50046 6.00003 7.50046C6.27617 7.50046 6.50003 7.72432 6.50003 8.00046Z"
                            fill="#F52419"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4 pb-4">
                  <SelectDropdown
                    label="Scheduling"
                    error={formErrors?.schedule}
                    optionsList={SCHEDULE}
                    defaultText={
                      Array.isArray(SCHEDULE) && typeof SCHEDULE[0] === "string"
                        ? state?.schedule
                        : SCHEDULE?.find(
                          ({ value }) => value === state?.schedule
                        )?.title || ""
                    }
                    placeholder={"Select Scheduling"}
                    onSelectChange={onSelectChange}
                    name="schedule"
                  />
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <FileInput
                    label="Keywords"
                    error={formErrors?.KEYWORDS}
                    file={state?.KEYWORDS || ""}
                    setState={setState}
                    name="KEYWORDS"
                    dropzoneToAccept={{
                      "text/plain": [],
                      "text/csv": [],
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4 pb-4">
                  <SelectDropdown
                    label="CTR Model Type"
                    optionsList={MODAL_TYPE}
                    error={formErrors?.modalType}
                    defaultText={
                      Array.isArray(MODAL_TYPE) &&
                        typeof MODAL_TYPE[0] === "string"
                        ? state?.modalType
                        : MODAL_TYPE?.find(
                          ({ value }) => value === state?.modalType
                        )?.title || ""
                    }
                    placeholder="Select CTR Model Type"
                    onSelectChange={onSelectChange}
                    name="modalType"
                  />
                </div>
                {!state?.modalType && (
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="flex items-center justify-center h-full">
                      <p className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Select Model Type to Update Model file or Params
                      </p>
                    </div>
                  </div>
                )}

                {state?.modalType !== null && state?.modalType === "param" && (
                  <div className="w-full lg:w-6/12 px-4 pb-4">
                    <SelectDropdown
                      label="CTR Model"
                      error={formErrors?.predefined_CTR}
                      optionsList={[
                        "Technology & Computing",
                        "Real Estate",
                        "Careers",
                        "Family & Parenting",
                        "Automotive",
                        "Law, Government, & Politics",
                        "Personal Finance",
                        " Hobbies & Interests",
                        "Shopping",
                        "Health & Fitness",
                        "Home & Garden",
                        "Business",
                        "Sports",
                        "News / Weather / Information",
                        "Food & Drink",
                        "Travel",
                        "Arts & Entertainment",
                        "Style & Fashion",
                        "Science",
                        "Education",
                        "Society",
                        "Pets",
                        "Religion & Spirituality",
                        "Illegal Content",
                        "Non-Standard Content	",
                        "Uncategorized",
                      ]}
                      defaultText={state?.predefined_CTR || ""}
                      placeholder="Select Scheduling"
                      onSelectChange={onSelectChange}
                      name="predefined_CTR"
                    />
                  </div>
                )}

                {state?.modalType !== null && state?.modalType === "file" && (
                  <div className="w-full lg:w-6/12 px-4">
                    <FileInput
                      label="CTR Model"
                      name="custom_CTR"
                      error={formErrors?.custom_CTR}
                      file={state?.custom_CTR || ""}
                      setState={setState}
                      dropzoneToAccept={{
                        "text/csv": [],
                        "application/vnd.ms-excel": [],
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                          [],
                      }}
                    />
                  </div>
                )}
              </div>

              <div className="flex flex-wrap">
                <div className="w-full px-4 pb-4">
                  <SelectDropdown
                    label="Data Source"
                    error={formErrors?.dataSource}
                    optionsList={DATA_SOURCE}
                    defaultText={
                      Array.isArray(DATA_SOURCE) &&
                        typeof DATA_SOURCE[0] === "string"
                        ? state?.dataSource
                        : DATA_SOURCE?.find(
                          ({ value }) => value === state?.dataSource
                        )?.title || ""
                    }
                    placeholder="Select Data Source"
                    onSelectChange={onSelectChange}
                    name="dataSource"
                  />
                </div>
              </div>
              <div className="flex flex-wrap">
                {!state?.dataSource && (
                  <div className="w-full px-4 pb-4">
                    <div className="flex items-center justify-center h-full">
                      <p className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Select Data Source To Update the File
                      </p>
                    </div>
                  </div>
                )}

                <div className="w-full px-4">
                  {state?.dataSource === "semrush" && (
                    <FileInput
                      setState={setState}
                      error={formErrors?.semrush}
                      file={state?.semrush || ""}
                      label="Data Source File"
                      dropzoneToAccept={{
                        "text/csv": [],
                        "application/vnd.ms-excel": [],
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                          [],
                      }}
                      name={"semrush" || ""}
                    />
                  )}
                </div>
              </div>
              {state?.dataSource === "gsc_and_semrush" && (
                <div className="flex flex-wrap ">
                  <div className="w-full lg:w-6/12 px-4 pb-4">
                    <div className="relative w-full">
                      <FileInput
                        setState={setState}
                        file={state?.semrush || ""}
                        error={formErrors?.semrush}
                        label="Semrush File"
                        dropzoneToAccept={{
                          "text/csv": [],
                          "application/vnd.ms-excel": [],
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                            [],
                          ".fe": [],
                        }}
                        name={"semrush" || ""}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full">
                      <FileInput
                        setState={setState}
                        file={state?.gsc || ""}
                        error={formErrors?.gsc}
                        label="Google File"
                        dropzoneToAccept={{
                          "text/csv": [],
                          "application/vnd.ms-excel": [],
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                            [],
                          ".fe": [],
                        }}
                        name={"gsc" || ""}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="flex items-center gap-4 justify-center w-full">
                <button
                  disabled={state?.isSubmitEnabled}
                  type="submit"
                  className={clsx(
                    "button_class_check",
                    " py-4 px-20 text-white rounded-lg  ",
                    loading
                      ? "button_loading_style"
                      : " bg-lightBlue-600 button-class-hover "
                  )}
                >
                  {loading && (
                    <span className="button-loader-icon">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10 18.25C9.58579 18.25 9.25 17.9142 9.25 17.5V14.714C9.25 14.2998 9.58579 13.964 10 13.964C10.4142 13.964 10.75 14.2998 10.75 14.714V17.5C10.75 17.9142 10.4142 18.25 10 18.25ZM10 6.03618C9.58578 6.03618 9.25 5.70039 9.25 5.28618V2.5C9.25 2.08579 9.58578 1.75 10 1.75C10.4142 1.75 10.75 2.08579 10.75 2.5V5.28618C10.75 5.70039 10.4142 6.03618 10 6.03618ZM4.16657 14.773C3.87368 15.0658 3.87368 15.5407 4.16657 15.8336C4.45946 16.1265 4.93434 16.1265 5.22723 15.8336L7.1972 13.8636C7.49009 13.5708 7.49009 13.0959 7.1972 12.803C6.9043 12.5101 6.42943 12.5101 6.13654 12.803L4.16657 14.773ZM12.803 6.13648C12.5102 6.42937 12.5102 6.90424 12.803 7.19714C13.0959 7.49003 13.5708 7.49003 13.8637 7.19714L15.8338 5.22701C16.1267 4.93412 16.1267 4.45924 15.8338 4.16635C15.5409 3.87346 15.0661 3.87346 14.7732 4.16635L12.803 6.13648ZM2.5 9.25C2.08579 9.25 1.75 9.58579 1.75 10C1.75 10.4142 2.08579 10.75 2.5 10.75H5.28596C5.70017 10.75 6.03596 10.4142 6.03596 10C6.03596 9.58579 5.70017 9.25 5.28596 9.25H2.5ZM14.7138 9.25C14.2996 9.25 13.9638 9.58579 13.9638 10C13.9638 10.4142 14.2996 10.75 14.7138 10.75H17.5C17.9142 10.75 18.25 10.4142 18.25 10C18.25 9.58579 17.9142 9.25 17.5 9.25H14.7138ZM14.773 15.8336C15.0659 16.1265 15.5408 16.1265 15.8337 15.8336C16.1266 15.5407 16.1266 15.0658 15.8337 14.773L13.8637 12.803C13.5708 12.5101 13.0959 12.5101 12.803 12.803C12.5102 13.0959 12.5102 13.5708 12.803 13.8636L14.773 15.8336ZM6.13654 7.19714C6.42943 7.49003 6.90431 7.49003 7.1972 7.19714C7.49009 6.90425 7.49009 6.42937 7.1972 6.13648L5.22707 4.16635C4.93418 3.87346 4.45931 3.87346 4.16641 4.16635C3.87352 4.45925 3.87352 4.93412 4.16641 5.22701L6.13654 7.19714Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  )}
                  Submit
                </button>
                {state?.isSubmitEnabled &&
                  <button
                    type="button"
                    onClick={(e) => {
                      if (state?.keywords?.length > 0) {
                        setState((prev) => {
                          return {
                            ...prev,
                            isSubmitEnabled: false,
                          };
                        });
                      } else {
                        CustomToast("Confirm it by adding keywords first.");
                      }
                    }}
                    className={clsx(
                      "bg-lightBlue-600",
                      " py-4 text-white rounded-lg  ",
                      "px-20  button-class-hover "
                    )}
                  >
                    Confirm
                  </button>
                }
              </div>
            </div>
            <hr className="mt-6 border-b-1 border-blueGray-300" />
          </form>
        </div>
        <KeywordTable keywords={state?.keywords} setKeywords={setState} />

      </div>

    </>
  );
};

export default ProjectView;
