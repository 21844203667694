import ToastMessage from "components/ToastMessage";
import { renderToString } from "react-dom/server";
import { toast } from "react-toastify";

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
};

export const hideBodyScroll = () => {
  if (typeof window !== "undefined") {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
  }
};

export const showBodyScroll = () => {
  if (typeof window !== "undefined") {
    document.getElementsByTagName("body")[0].style.overflow = "auto";
  }
};

export const CustomToast = (toastLabel) => {
  setTimeout(toast.dismiss(), 700);
  return toast(<ToastMessage toastLabel={toastLabel} />, {
    position: "bottom-center",
    hideProgressBar: true,
    closeButton: false,
  });
};

export const parseCSV = (csvText) => {
  return csvText
    .split("\n")
    .map((line) => line.split(","))
    .flat();
};

export const handleFormValidation = (state) => {
  const {
    domain,
    name,
    KEYWORDS,
    schedule,
    modalType,
    predefined_CTR,
    custom_CTR,
    dataSource,
    semrush,
  } = state || {};

  if (!domain) {
    return {
      error: {
        domain: "Domain is required",
      },
    };
  }
  if (!name) {
    return {
      error: {
        name: "Project Name is required",
      },
    };
  }
  if (!KEYWORDS) {
    return {
      error: {
        KEYWORDS: "Keywords is required",
      },
    };
  }
  if (!schedule) {
    return {
      error: {
        schedule: "Schedule is required",
      },
    };
  }
  if (!modalType) {
    return {
      error: {
        modalType: "ModalType is required",
      },
    };
  }
  if (modalType) {
    if (modalType === "param") {
      if (!predefined_CTR) {
        return {
          error: {
            predefined_CTR: "Predefined CTR need to be Selected",
          },
        };
      }
    }
    if (modalType === "file") {
      if (!custom_CTR) {
        return {
          error: {
            custom_CTR: "Custom CTR file needs to be added",
          },
        };
      }
    }
  }
  if (!dataSource) {
    return {
      error: {
        dataSource: "DataSource needs to be Selected",
      },
    };
  }
  if (dataSource) {
    if (dataSource === "semrush")
      if (!semrush) {
        return {
          error: {
            semrush: "Semrush perfomance File is required",
          },
        };
      }
  }
  if (dataSource === "gsc_and_semrush") {
    if (!semrush) {
      return {
        error: {
          semrush: "Semrush perfomance File is required",
        },
      };
    }
  }
  return { error: null };
};

export const getNameInitials = (name) => {
  const words = name.split(" ");
  const initials = words.map((word) => word.charAt(0));
  return initials.join("");
};

export const getDecimalLength = (number) => {
  let numberString = number?.toString();

  let decimalPosition = numberString?.indexOf(".");

  return decimalPosition === -1
    ? 0
    : numberString?.length - decimalPosition - 1;
};
export const average = (array) => {
  const sum = array.reduce((acc, currentValue) => acc + currentValue, 0);
  // Calculate the average
  const average = sum / array.length;
  const nearestValue = array.reduce((closest, currentValue) => {
    const currentDiff = Math.abs(currentValue - average);
    const closestDiff = Math.abs(closest - average);
    return currentDiff < closestDiff ? currentValue : closest;
});
  return nearestValue;
};

export const getSortedData = (tableData) => {
  const indices = tableData?.keyword
    ? Array.from(tableData.keyword.keys())
    : [];
  // Sort the indices based on the alphabetical order of keywords
  indices?.sort((a, b) =>
    tableData?.keyword[a]?.localeCompare(tableData?.keyword[b])
  );

  // Use the sorted indices to rearrange all arrays in the data object
  return {
    Position: indices?.map((i) => tableData?.Position[i]),
    historical_Traffic: indices?.map((i) => tableData?.historical_Traffic[i]),
    keyword: indices?.map((i) => tableData?.keyword[i]),
    mention_in_snapshot: indices?.map((i) => tableData?.mention_in_snapshot[i]),
    snapshot_shopping_local: indices?.map(
      (i) => tableData?.snapshot_shopping_local[i]
    ),
    traffic_Loss: indices?.map((i) => tableData?.traffic_Loss[i]),
  };
};

export const getPieChartData = (data = [], colors = [],handleClick) => {
  const labels =
    Array.isArray(data) &&
    data?.map(({ key }) =>
      key
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ")
    );

  const calSeries = Array.isArray(data)
    ? [...data?.map(({ value }) => value)]
    : [];
  const validValues = calSeries.filter(
    (value) => value !== undefined && value !== null
  );
  const series = validValues?.map(
    (value) =>
      (value /
        validValues?.reduce((partialSum, value) => partialSum + value, 0)) *
      100
  );
  const chartData = {
    series: series || [],
    options: {
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => { 
            handleClick && handleClick(config.w.config.labels[config.dataPointIndex])}
        }
      },    
      stroke: {
        width: calSeries?.length > 1 ? 2 : 0,
      },
      fill: {
        colors: colors.slice(0, series?.length),
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%"
            },
          }
        },
        {
          breakpoint: 720,
          options: {
            chart: {
              width: "100%"
            },
          }
        },
        {
          breakpoint: 1024,
          options: {
            chart: {
              width: 300
            },
          }
        }
      ],
      labels: labels || [],
      legend: {
        show: true,
        position: "bottom",
        markers: {
          fillColors: colors.slice(0, series?.length),
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, w }) {
          const {
            globals: {
              labels,
              fill: { colors },
            },
          } = w;
          return (
            `<div class="arrow_box " style="background: ${colors[seriesIndex]};">` +
            "<span>" +
            labels[seriesIndex] +
            ": " +
            series[seriesIndex].toFixed(3) +
            "</span>" +
            "</div>"
          );
        },
      },
    },
  };

  return chartData;
};

export const getHistogramDataObject = (data = [], name) => {
  const countArray = data?.map((_, i) => i);
  const positionArray = data;
  const avg = average(data);
  console.log(data);
  console.log(avg);
  const historgram = {
    series: [
      {
        name: name,
        data: Array?.from({ length: positionArray.length }, (_, i) => ({
          x: 0.5 + i,
          y: positionArray[i],
          topLable: name,
          bgCLass:
            avg === positionArray[i] ? "rounded-icon-blue" : "rounded-icon-red",
          title: avg === positionArray[i] ? `Average ${name}` : name,
          ...(avg === positionArray[i]
            ? {
                fillColor: "rgba(32, 120, 255, 0.4)",
                strokeColor: "#80afff",
              }
            : {}),
        })),
      },
    ],
    plotOptions: {
      bar: {
        columnWidth: "95%",
        strokeWidth: 2,
        borderRadius: 5,
        borderRadiusApplication: "end",
      },
    },
    fill: {
      colors: "#ff4040",
      opacity: 0.3,
    },
    stroke: {
      width: 2,
      colors: ["#ee8080"],
    },
    dataLabels: { enabled: false },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      type: "numeric",
      min: 0,
      max: countArray.length,
      tickAmount: countArray.length,
      labels: { formatter: (x) => x },
      title: { text: "Hours" },
      axisBorder: {
        color: "#000000",
      },
    },
    yaxis: {
      title: { text: "Load Time" },
      min: 0,
      max: Math.max(...positionArray),
      axisBorder: {
        show: true,
        color: "#000000",
      },
    },
    tooltip: {
      onDatasetHover: {
        highlightDataSeries: true,
      },
      custom: function ({ seriesIndex, dataPointIndex, w }) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        const { title, y, topLable, bgCLass } = data || {};
        return renderToString(
          <div className="flex flex-col gap-2 shadow-lg rounded-tooltip">
            <div className="top_header px-2 py-2">{topLable}</div>
            <div className="px-2 py-2 justify-center items-center flex gap-2 bottom_text">
              <div className={bgCLass}></div>
              <p>{title}: </p>
              <p>{y}</p>
            </div>
          </div>
        );
      },
    },
  };

  return historgram;
};

export const getRandomElements = (array, count) => {
  // Create a copy of the array to avoid modifying the original array
  let shuffledArray = array.slice();

  // Shuffle the array using the Fisher-Yates algorithm
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  // Return the first 'count' elements of the shuffled array
  return shuffledArray.slice(0, count);
};

export const getLoadTimeData = (data = []) => {
  const loadTime = data?.map(({ loadTime }) => loadTime);
  const sortedTimeArray = loadTime?.sort((a, b) => a - b);
  // Initialize variables
  let loadCounts = [];
  let currentSecond = Math.floor(sortedTimeArray[0] / 1000);
  let loadCount = 0;

  // Count loads in each second interval
  sortedTimeArray.forEach((time) => {
    const second = Math.floor(time / 1000);
    if (second === currentSecond) {
      loadCount += 1;
    } else {
      loadCounts.push({ second: currentSecond, count: loadCount });
      currentSecond = second;
      loadCount = 1;
    }
  });
  loadCounts.push({ second: currentSecond, count: loadCount });
  const countArr = loadCounts?.map(({ count }) => count);
  const avg = average(countArr);

  const uniqueXValues = Array.from(
    new Set(loadCounts.map((item) => item.second))
  );
  const relevantXValues = uniqueXValues.filter((value) =>
    loadCounts.some((item) => item.second === value)
  );

  const resp = loadCounts.map(({ count, second },i) => ({
    dataPointIndexParent :i,
    second,
    count,
    topLabel: "Keyword Count",
    bgClass: avg === count ? "rounded-icon-blue" : "rounded-icon-red",
    title: avg === count ? "Average Count" : "Count",
    secTitle: avg === count ? "Average Second" : "Second",
  }));

  const histogram = {
    series: [
      {
        name: "Keyword Count",
        data: loadCounts.map(({count})=>count)
      },
    ],
    options: {
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%"
            },
          }
        },
        {
          breakpoint: 720,
          options: {
            chart: {
              width: "100%"
            },
          }
        },
        {
          breakpoint: 1024,
          options: {
            chart: {
              width: "100%"
            },
          }
        }
      ],
      plotOptions: {
        bar: {
          strokeWidth: 2,
          borderRadius: 5,
          borderRadiusApplication: "end",
        },
      },
      fill: {
        opacity: 0.3,
        colors: [function({ value}) {
          if(value === avg) {
              return "rgba(32, 120, 255, 0.4)"
          }  else {
              return "#ff4040"
          }
        }],
      },
      stroke: {
        width: 2,
       colors:["rgba(0, 0, 0, 0.3)"],
       opacity:0.3
       },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        title: {
          text: "Seconds",
        },
        categories: [...loadCounts?.map(({ second }) => second)],
        tickPlacement: "on",
        axisBorder: {
          color: "#000000",
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "12px",
          fontWeight: "bold",
          colors: ["#000000"],
        },
      },
      yaxis: {
        title: {
          text: "Keywords Count",
        },

        axisBorder: {
          show: true,
          color: "#000000",
        },
      },
      tooltip: {
        onDatasetHover: {
          highlightDataSeries: true,
        },
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          // var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          const { title, second,secTitle,
            count, topLabel, bgClass }  = resp?.find(({dataPointIndexParent})=>dataPointIndexParent=== dataPointIndex);
          return renderToString(
            <div className="flex flex-col gap-2 shadow-lg rounded-tooltip">
              <div className="top_header px-2 py-2">{topLabel}</div>
              <div className="px-2 py-2 justify-center items-center flex gap-2 bottom_text">
                <div className="flex flex-col gap-2">
          
                <div className=" flex gap-2 items-center">
                <div className={bgClass}></div>
                <p>{title}: </p>
                <p>{count}</p>
                </div>
                <div className="flex gap-2 items-center">
                <div className={bgClass}></div>
                <p>{secTitle}: </p>
                <p>{second}</p>

                </div>

                </div>
              </div>
            </div>
          );
        },
      },
    },
  };

  return histogram;
};
