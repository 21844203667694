import { initializeApp, getApps } from "firebase/app";
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  connectAuthEmulator,
  EmailAuthProvider,
  reauthenticateWithCredential,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import {
  getFirestore,
  connectFirestoreEmulator,
  doc,
  getDoc,
} from "firebase/firestore";

import { getStorage, connectStorageEmulator } from "firebase/storage";
import { CustomToast } from "utils/helpers";

const config = {
  apiKey: process.env.REACT_APP_FIREABSE_API_KEY,
  authDomain: process.env.REACT_APP_FIREABSE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREABSE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREABSE_STORAGE_BUCKET,
  senderId: process.env.REACT_APP_FIREABSE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREABSE_APP_ID,
};

let emulatorConnected = false;
const useEmulators = process.env.REACT_APP_EMULATOR === "true";
const authEmulatorUrl = "http://127.0.0.1:9099";
const emulatorHost = "127.0.0.1";
const firestoreEmulatorPort = 8080;
const googleAuthProvider = new GoogleAuthProvider();
const storageEmulatorPort = 9199;
export const ERRORS_CODE_MESSAGES_MAP = {
  "auth/wrong-password": "Incorrect email or password.",
  "auth/user-not-found": "Email does not exist.",
  "auth/weak-password": "Password should be at least 6 characters.",
  "auth/invalid-email": "Invalid email.",
  "auth/too-many-requests":
    "You have sent too many requests. Please try again later.",
  "auth/email-already-in-use": "Email is already in use",
  "auth/requires-recent-login":
    "Credentials too old. Please log in again to perform this action",
};

class Firebase {
  app;
  auth;
  firestore;
  storage;

  constructor() {
    if (useEmulators) {
      !getApps().length &&
        (this.app = initializeApp({
          apiKey: config.apiKey,
          projectId: config.projectId,
          storageBucket: `gs://${config.storageBucket}`,
        }));
      this.auth = getAuth(this.app);
      this.provider = GoogleAuthProvider();
      this.storage = getStorage(this.app);
      this.firestore = getFirestore(this.app);
      if (!emulatorConnected) {
        connectAuthEmulator(this.auth, authEmulatorUrl, {
          disableWarnings: true,
        });
        connectFirestoreEmulator(
          this.firestore,
          emulatorHost,
          firestoreEmulatorPort
        );
        connectStorageEmulator(this.storage, emulatorHost, storageEmulatorPort);
        emulatorConnected = true;
      }
    } else {
      !getApps().length && (this.app = initializeApp(config));
      this.auth = getAuth(this.app);
      this.storage = getStorage();
      this.firestore = getFirestore(this.app);
    }
  }

  getCurrentUser = async () => {
    return this.auth.currentUser;
  };
  getCurrentUserAccessToken = async () => {
    if(this.auth.currentUser){
      return this.auth.currentUser.getIdToken();
    }
    else{
      CustomToast("No User found")
    }
  };

  getUser = async (uid) => {
    if (!uid) return { success: false };

    try {
      const docRef = doc(this.firestore, "users", uid);
      const userData = await getDoc(docRef);
      return userData.data();
    } catch (error) {
      console.log(error);
      const code = error.code;
      return {
        error: {
          code,
          message: ERRORS_CODE_MESSAGES_MAP[code],
        },
      };
    }
  };

  signIn = async () => {
    try {
      const result = await signInWithPopup(this.auth, googleAuthProvider);
      const user = result.user;
      return {
        user,
        success: true,
      };
    } catch (error) {
      const code = error.code;
      const message = error.message;
      return {
        success: false,
        error: {
          code,
          message,
        },
      };
    }
  };

  reAuthenticateUser = async (password, user) => {
    if (!user || !password) return { success: false };

    const { email } = user;
    try {
      const credentials = EmailAuthProvider.credential(email, password);

      const result = await reauthenticateWithCredential(
        this.auth.currentUser,
        credentials
      );

      return { success: true, result };
    } catch (error) {
      const code = error.code;
      console.log(code);
      return {
        error: {
          code,
          message: ERRORS_CODE_MESSAGES_MAP[code],
        },
      };
    }
  };


  signOut = () => signOut(this.auth);

  sessionObserver = (callback, setError) =>
    onAuthStateChanged(
      this.auth,
      async (state) => {
        try {
          await callback(state);
        } catch (error) {
          setError(error);
        }
      },
      setError
    );
}

export default Firebase;
