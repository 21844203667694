import React, { useEffect, useState } from "react";
// components

import "assets/styles/posthtml.scss";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { CustomToast } from "utils/helpers";
import clsx from "clsx";
import PublicView from "components/PublicView";
import { useFirebase } from "components/Firebase";

export default function PostHTML( { isPublicView = false, kid }) {

  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState({});
  const { getCurrentUserAccessToken } = useFirebase();
  const { id } = useParams();
  const history = useHistory();

  const getProjectById = async () => {
    const accessToken = await getCurrentUserAccessToken();
    try {
      setLoading(true);
      const { data: { data: { keyword } = {} } = {} } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/projects/${id}/keywords/${kid}`,
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
      setResponse(keyword);
      setLoading(false);
    } catch (error) {
      const {
        response: { data },
      } = error || {};

      const { error: err } = data || {};
      CustomToast(err || data || "");
      console.log(error);
      history.push("/admin/");
      setLoading(false);
    }
  };

  const getPublicViewResponse = async () => {
    try {
      setLoading(true);
      const { data: { data: { keyword } = {} } = {} } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/share/${id}/${kid}`
      );
      setResponse(keyword);
      setLoading(false);
    } catch (error) {
      const {
        response: { data },
      } = error || {};

      const { error: err } = data || {};
      CustomToast(err || data || "");
      console.log(error);
      history.push("/");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (PublicView) {
      getPublicViewResponse();
    } else {
      getProjectById();
    }
  }, [id]);

  return (
    <>
      {loading ? (
        <>
          <div
            className={clsx(isPublicView && "public_view_color", "relative")}
          >
            <div className={clsx("md:pt-12  pt-12")}>
              <div className="flex justify-center loader_screen items-center">
                <span className="loader-spinner"></span>
              </div>
            </div>
          </div>
        </>
      ) : response?.postClickHtml !== null ? (
        <div className={clsx("public_view_color", "relative")}>
          <div
            className="postClickHtml"
            dangerouslySetInnerHTML={{ __html: response?.postClickHtml }}
          ></div>
        </div>
      ) : (
        <p className="w-full justify-center items-center flex py-10 text-lg font-bold font-sans px-4">SGE snapshot not available </p>
      )}
    </>
  );
}
