import { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "./context";

const useFirebase = () => {
  const firebase = useContext(FirebaseContext);
  return firebase;
};

const useSession = () => {
  const { getCurrentUser } = useFirebase();
  return getCurrentUser;
};

const useIsOnboarded = () => {
  const [onboarding, setOnboarding] = useState();
  const { isUserOnboarded } = useFirebase();
  const { user } = useSession();

  const getOnboardingStatus = async () => {
    const res = await isUserOnboarded();
    setOnboarding(res);
  };

  useEffect(() => {
    getOnboardingStatus();
    // eslint-disable-next-line
  }, [user]);

  return {
    onboarding,
  };
};

export { useFirebase, useIsOnboarded };
