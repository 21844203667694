import React, { useState, useEffect } from 'react'

import "assets/styles/linkscomponent.scss"
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import clsx from 'clsx';
import axios from 'axios';
import { useFirebase } from 'components/Firebase';
import { CustomToast } from 'utils/helpers';



const LinksComponent = ({nodes,setResponse}) => {
    const { getCurrentUserAccessToken } = useFirebase();
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const { id } = useParams()
    const getUrl = (shareID) => {
        return `${window?.location?.origin}/share/${id}/${shareID}`
    }

    const copy = (shareID) => {

        if (typeof window !== "undefined") {
            const el = document.createElement("input");
            el.value = `${window?.location?.origin}/share/${id}/${shareID}`;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);

            const newTab = window.open(
                `${window?.location?.origin}/share/${id}/${shareID}`,
                "_blank"
            );
            if (newTab) {
                newTab.focus();
            } else {
                console.error(
                    "Unable to open the URL in a new tab. Please check your browser settings."
                );
            }
        }
    };

    const handleLinkAddition = async (e) => {
        e.preventDefault();
        const [email] = document.getElementById("linkForm").elements
        try {
            setLoading(true);
            const accessToken = await getCurrentUserAccessToken();
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/v1/projects/${id}/share`,
                {
                    "email": email.value
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                }
            );
       
            console.log(response);
            if (response?.status === 200) {
                const {data} = response || {}
                const objToAppend = {
                    link:   data?.link,
                    email: data?.email,
                    click_count:data?.click_count
                }
                setResponse((prev)=>{
                    const {links=[]} = prev 
                    links.push(objToAppend)
                    return {
                        ...prev,
                        links
                    }
                })
                CustomToast("Link Generated Successfully");
            }
        } catch (error) {
            const {
                response: {
                    data: { errors },
                },
            } = error;
            CustomToast(errors?.messages.toString() || "Something went wrong");
        }
        finally {
            setLoading(false);
            setShowForm(false);
        }
    }

    return (
        <div className="">
            <div className="flex justify-between items-center px-6">
                <div className='flex flex-col'>
                    <h6 className="text-blueGray-400 text-sm pb-2 font-bold uppercase">
                        All Links
                    </h6>
                    <p className='text-blueGray-600 text-xs  font-medium uppercase'>Click the Links below to copy and share</p>
                </div>
                <button onClick={()=>setShowForm(true)} className=" dropdown_button_hover addition active:outline-none focus:outline-none  px-4 py-2 flex justify-left items-center gap-3 text-sm font-normal leading-5 font-poppins text-black w-fit">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                    >
                        <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>
                    Add Link
                </button>
            </div>

            {showForm && <form id='linkForm' onSubmit={(e) => {
                if (!loading) {
                    handleLinkAddition(e);
                }
            }} className="flex justify-center items-center gap-4 pt-4 pb-4 form_class">
                <label htmlFor='email' >Email</label>
                <div className='flex form_child_class w-full'>
                    <input
                        id="email"
                        name="email"
                        placeholder='Enter the email'
                        type='email'
                        className="outline-0 px-3 py-3 border-0 text-sm  w-full  text-blueGray-600 focus:nooutline"

                    />
                    <button type="submit" className={clsx(loading ? "loading-occur" : "loading-default", "active:outline-none focus:outline-none  px-4 py-2 flex justify-left items-center gap-3 text-sm font-normal leading-5 font-poppins text-black w-fit")}>
                        {loading && (
                            <span className="button-loader-icon">
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M10 18.25C9.58579 18.25 9.25 17.9142 9.25 17.5V14.714C9.25 14.2998 9.58579 13.964 10 13.964C10.4142 13.964 10.75 14.2998 10.75 14.714V17.5C10.75 17.9142 10.4142 18.25 10 18.25ZM10 6.03618C9.58578 6.03618 9.25 5.70039 9.25 5.28618V2.5C9.25 2.08579 9.58578 1.75 10 1.75C10.4142 1.75 10.75 2.08579 10.75 2.5V5.28618C10.75 5.70039 10.4142 6.03618 10 6.03618ZM4.16657 14.773C3.87368 15.0658 3.87368 15.5407 4.16657 15.8336C4.45946 16.1265 4.93434 16.1265 5.22723 15.8336L7.1972 13.8636C7.49009 13.5708 7.49009 13.0959 7.1972 12.803C6.9043 12.5101 6.42943 12.5101 6.13654 12.803L4.16657 14.773ZM12.803 6.13648C12.5102 6.42937 12.5102 6.90424 12.803 7.19714C13.0959 7.49003 13.5708 7.49003 13.8637 7.19714L15.8338 5.22701C16.1267 4.93412 16.1267 4.45924 15.8338 4.16635C15.5409 3.87346 15.0661 3.87346 14.7732 4.16635L12.803 6.13648ZM2.5 9.25C2.08579 9.25 1.75 9.58579 1.75 10C1.75 10.4142 2.08579 10.75 2.5 10.75H5.28596C5.70017 10.75 6.03596 10.4142 6.03596 10C6.03596 9.58579 5.70017 9.25 5.28596 9.25H2.5ZM14.7138 9.25C14.2996 9.25 13.9638 9.58579 13.9638 10C13.9638 10.4142 14.2996 10.75 14.7138 10.75H17.5C17.9142 10.75 18.25 10.4142 18.25 10C18.25 9.58579 17.9142 9.25 17.5 9.25H14.7138ZM14.773 15.8336C15.0659 16.1265 15.5408 16.1265 15.8337 15.8336C16.1266 15.5407 16.1266 15.0658 15.8337 14.773L13.8637 12.803C13.5708 12.5101 13.0959 12.5101 12.803 12.803C12.5102 13.0959 12.5102 13.5708 12.803 13.8636L14.773 15.8336ZM6.13654 7.19714C6.42943 7.49003 6.90431 7.49003 7.1972 7.19714C7.49009 6.90425 7.49009 6.42937 7.1972 6.13648L5.22707 4.16635C4.93418 3.87346 4.45931 3.87346 4.16641 4.16635C3.87352 4.45925 3.87352 4.93412 4.16641 5.22701L6.13654 7.19714Z"
                                        fill="white"
                                    />
                                </svg>
                            </span>
                        )}
                        Submit
                    </button>
                </div>

            </form>}
            <div className='pt-2'>
                <table className="items-center w-full bg-transparent border-collapse ">
                    <thead>
                        <tr>
                            <th
                                className="px-6 align-middle border border-solid py-3 bg-blueGray-50 text-blueGray-500 border-blueGray-100 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left "
                            >
                                Email
                            </th>
                            <th
                                className="px-6 align-middle border border-solid py-3 bg-blueGray-50 text-blueGray-500 border-blueGray-100 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left "
                            >
                                Link
                            </th>
                            <th
                                className="px-6 align-middle border border-solid py-3 bg-blueGray-50 text-blueGray-500 border-blueGray-100 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left "
                            >
                                Activity
                            </th>


                        </tr>
                    </thead>
                    {nodes?.length > 0 &&
                        <tbody>

                            {nodes?.map(({ email, click_count, link }) => {
                                return (
                                    <tr onClick={() => { copy(link) }} className="default">
                                        <td className=" py-4 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  font-semibold leading-6 text-sm">
                                            {email}
                                        </td>
                                        <td  className={clsx("style_link", " relative py-4 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  font-semibold leading-6 text-sm")}>
                                            <p>{getUrl(link)}</p>
                                        </td>
                                        <td className="py-4 border-t-0 px-6 flex gap-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  font-semibold leading-6 text-sm">

                                            {click_count}
                                            <p >
                                                Visits

                                            </p>
                                        </td>

                                    </tr>
                                )
                            })}

                        </tbody>
                    }
                </table>
                {nodes?.length === 0 &&
                    <div className='py-6 flex justify-center items-center w-full font-semibold text-lg text-blueGray-400 '>
                        No Links Generated
                    </div>}

            </div>




        </div>
    )
}

export default LinksComponent
