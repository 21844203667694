import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useRef, useState } from "react";
import PostHTML from "components/PostHtml";
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
import { useCallback } from "react";
import * as XLSX from "xlsx-js-style";
import { FilterMatchMode, FilterService } from "primereact/api";
import tooltipIcon from "../../assets/img/tooltip-icon.png";
import { InputText } from "primereact/inputtext";

const DashboardTableComponent = ({ heading, subHeading, data, name }) => {
  FilterService.register("custom_keyword", (value, filters) => {
    const filtersData = filters.replace(/\\/g, "/");
    const regExpConstructor = new RegExp(filtersData, "gi"); // With flag -g
    console.log(regExpConstructor);
    const leates = value?.match(regExpConstructor);
    return leates;
  });
  const [expandedRows, setExpandedRows] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    keyword: { value: null, matchMode: FilterMatchMode.CUSTOM },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    snapshot_shopping_local: { value: null, matchMode: FilterMatchMode.EQUALS },
    Position: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const informations = [
    ...new Set(
      data?.map(({ snapshot_shopping_local }) => snapshot_shopping_local)
    ),
  ];
  const informationsUnique = informations?.map((node) => {
    return {
      label: node,
      value: node,
    };
  });
  const positionses = [...new Set(data?.map(({ Position }) => Position))];
  const positionUnique = positionses?.map((node) => {
    return {
      label: node,
      value: node,
    };
  });
  const statuses = [...new Set(data?.map(({ status }) => status))];
  const statusesUnique = statuses?.map((node) => {
    return {
      label: node,
      value: node,
    };
  });

  const dt = useRef(null);
  const TraficLoss = ({ traffic_Loss }) => {
    return (
      <>
        {traffic_Loss ? (
          <span className={"ml-3 font-normal " + "text-blueGray-900"}>
            {Math?.ceil(traffic_Loss)?.toLocaleString()}
          </span>
        ) : (
          <span className={"ml-3 font-normal " + "text-blueGray-900"}>0</span>
        )}
      </>
    );
  };
  const HistoricalTrafic = ({ historical_Traffic }) => {
    return (
      <>
        {historical_Traffic ? (
          <span className={"ml-3 font-normal " + "text-blueGray-900"}>
            {Math?.ceil(historical_Traffic)?.toLocaleString()}
          </span>
        ) : (
          <span className={"ml-3 font-normal " + "text-blueGray-900"}>0</span>
        )}
      </>
    );
  };

  const onRowExpand = (event) => {
    // setExpandedRows(event?.data);
    return true;
  };

  const onRowCollapse = (event) => {
    // setExpandedRows(null);
    return false;
  };

  const allowExpansion = (row) => {
    if (row?.status?.toLowerCase() === "yes") {
      return true;
    } else {
      return false;
    }
  };

  const rowExpansionTemplate = (data) => {
    const { id } = data || {};
    return <PostHTML kid={id} {...expandedRows} />;
  };
  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };
  const exportExcel = () => {
    const xlsxData = data?.map(
      ({
        keyword,
        historical_Traffic,
        traffic_Loss,
        snapshot_shopping_local,
        mention_in_snapshot,
        Position,
        status,
      }) => {
        return {
          keyword,
          historical_Traffic,
          traffic_Loss,
          snapshot_shopping_local,
          mention_in_snapshot,
          Position,
          status,
        };
      }
    );
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(xlsxData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "products");
    });
  };
  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statusesUnique}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select One"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };
  const informationsRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={informationsUnique}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select One"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };
  const positionsRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={positionUnique}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select One"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };

  const handleRowToggle = (data) => {
    setExpandedRows(data);
  };
  const handleClick = (e) => {
    if (e?.data?.status?.toLowerCase() === "yes") {
      if (expandedRows?.some((node) => node?.id === e?.data?.id)) {
        const updatedRow = expandedRows?.filter(
          (node) => node?.id !== e?.data?.id
        );
        setExpandedRows(updatedRow);
      } else {
        if (expandedRows) {
          const array = [...expandedRows, e?.data];
          handleRowToggle(array);
        } else {
          const array = [e?.data];
          handleRowToggle(array);
        }
      }
    }
  };
  const xlsxData = data.map(
    ({
      keyword,
      historical_Traffic,
      traffic_Loss,
      snapshot_shopping_local,
      mention_in_snapshot,
      Position,
      status,
    }) => {
      return [
        {
          v: keyword,
          s: {
            font: { name: "Arial", sz: "11" },
            alignment: { horizontal: "center", vertical: "center" },
          },
        },
        {
          v: historical_Traffic,
          t: "s",
          s: {
            font: { name: "Arial", sz: "11" },
            alignment: { horizontal: "center", vertical: "center" },
          },
        },
        {
          v: traffic_Loss,
          t: "s",
          s: {
            font: { name: "Arial", sz: "11" },
            alignment: { horizontal: "center", vertical: "center" },
          },
        },
        {
          v: snapshot_shopping_local,
          t: "s",
          s: {
            font: { name: "Arial", sz: "11" },
            alignment: { horizontal: "center", vertical: "center" },
          },
        },
        {
          v: mention_in_snapshot,
          t: "s",
          s: {
            font: { name: "Arial", sz: "11" },
            alignment: { horizontal: "center", vertical: "center" },
          },
        },
        {
          v: Position,
          t: "s",
          s: {
            font: { name: "Arial", sz: "11" },
            alignment: { horizontal: "center", vertical: "center" },
          },
        },
        {
          v: status,
          t: "s",
          s: {
            font: { name: "Arial", sz: "11" },
            alignment: { horizontal: "center", vertical: "center" },
          },
        },
      ];
    }
  );

  const exportFile = useCallback(() => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);
    const header = [
      "Schedule an AI Overviews Strategy Session with Mike King Today",
    ];
    const header2 = [
      "Your AI Overviews Threat Report Export consists of the key data pulled from the AI Overviews' impact on your domain.\nGoogle's AI Overviews (SGE) was announced at Google I/O 2024. The Threat Report is more than just data - it's a call to action.\nThe right strategies are crucial now to secure your place in a moment of SEO uncertainty and volatility.",
    ];
    XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
    XLSX.utils.sheet_add_aoa(ws, [header2], {
      origin: "D1",
    });
    let Heading = [
      [
        "Keyword",
        "Historical Traffic",
        "Traffic Loss",
        "SGE SERP Type",
        "Mention in Snapshot",
        "Position In Carousel",
        "AI Overview Appears?",
      ],
    ];
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A2" });
    const mergeRangeFirst = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 0 } },
      { s: { r: 0, c: 1 }, e: { r: 0, c: Heading[0].length } },
    ];
    ws["!merges"] = mergeRangeFirst;
    XLSX.utils.sheet_add_aoa(ws, xlsxData, { origin: "A3" });
    XLSX.utils.book_append_sheet(wb, ws, "readme demo");
    ws["A1"].l = {
      Target:
        "https://ipullrank.com/contact?utm_source=sge-threat-sheet-excel&utm_medium=excel&utm_campaign=sge-threat-report",
    };
    ws["A1"].s = {
      font: {
        name: "Arial",
        sz: 14,
        color: { rgb: "fbdd23" },
        bold: true,
      },
      alignment: { wrapText: true, vertical: "center" },
      fill: { fgColor: { rgb: "1759ff" } },
      border: { bottom: { style: "thin", color: { rgb: "ffffff" } } },
    };
    ws["D1"].s = {
      font: {
        name: "Arial",
        sz: 12,
        color: { rgb: "ffffff" },
      },
      alignment: { wrapText: true, vertical: "center" },
      fill: { fgColor: { rgb: "1759ff" } },
      border: { bottom: { style: "thin", color: { rgb: "ffffff" } } },
    };
    ws["A2"].s = {
      font: {
        name: "Arial",
        sz: 12,
        color: { rgb: "ffffff" },
        bold: true,
      },
      alignment: { horizontal: "center", vertical: "center" },
      fill: { fgColor: { rgb: "1759ff" } },
    };
    ws["B2"].s = {
      font: {
        name: "Arial",
        sz: 12,
        color: { rgb: "ffffff" },
        bold: true,
      },
      alignment: { horizontal: "center", vertical: "center" },
      fill: { fgColor: { rgb: "1759ff" } },
    };
    ws["C2"].s = {
      font: {
        name: "Arial",
        sz: 12,
        color: { rgb: "ffffff" },
        bold: true,
      },
      alignment: { horizontal: "center", vertical: "center" },
      fill: { fgColor: { rgb: "1759ff" } },
    };
    ws["D2"].s = {
      font: {
        name: "Arial",
        sz: 12,
        color: { rgb: "ffffff" },
        bold: true,
      },
      alignment: { horizontal: "center", vertical: "center" },
      fill: { fgColor: { rgb: "1759ff" } },
    };
    ws["E2"].s = {
      font: {
        name: "Arial",
        sz: 12,
        color: { rgb: "ffffff" },
        bold: true,
      },
      alignment: { horizontal: "center", vertical: "center" },
      fill: { fgColor: { rgb: "1759ff" } },
    };
    ws["F2"].s = {
      font: {
        name: "Arial",
        sz: 12,
        color: { rgb: "ffffff" },
        bold: true,
      },
      alignment: { horizontal: "center", vertical: "center" },
      fill: { fgColor: { rgb: "1759ff" } },
    };
    ws["G2"].s = {
      font: {
        name: "Arial",
        sz: 12,
        color: { rgb: "ffffff" },
        bold: true,
      },
      alignment: { horizontal: "center", vertical: "center" },
      fill: { fgColor: { rgb: "1759ff" } },
    };
    ws["!cols"] = [
      { wch: 55 },
      { wch: 25 },
      { wch: 25 },
      { wch: 40 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
    ];
    ws["!rows"] = [
      { hpx: 96 },
      { hpx: 35 },
      ...xlsxData.map((node) => {
        return { hpx: 24 };
      }),
    ];
    XLSX.writeFile(wb, `${name}-SGE Threat Report-IPULLRANK.xlsx`);
  }, [xlsxData]);

  const header = (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <h2 className=" class_heading">{heading}</h2>
        <div className="flex items-center gap-4">
          {subHeading && <h3 className=" class_sub_heading">{subHeading}</h3>}
          <button
            className="rounded-full w-10 h-10 csv-export-button"
            onClick={() => exportCSV(false)}
          >
            <i class="fa-solid fa-file-csv"></i>
          </button>
          <button
            onClick={() => exportFile()}
            className="rounded-full w-10 h-10 csv-export-button"
          >
            <i class="fa-solid fa-file-excel"></i>
          </button>
        </div>
      </div>
    </div>
  );

  const activityRowFilterTemplate = (options) => {
    return (
      <div className="flex gap-1">
        <InputText
          value={options.value}
          onChange={(e) => options.filterApplyCallback(e.target.value)}
          className="w-full h-10 pl-2"
          placeholder="Search by name"
        />
      </div>
    );
  };

  return (
    <div className="main z-index-class relative px-5 py-3">
      <Tooltip target=".export-buttons>button" position="bottom" />
      <DataTable
        filters={filters}
        filterDisplay="row"
        globalFilterFields={["keyword"]}
        ref={dt}
        header={header}
        expandedRows={expandedRows}
        onRowToggle={(e) => handleRowToggle(e?.data)}
        onRowClick={(e) => handleClick(e)}
        onRowExpand={onRowExpand}
        onRowCollapse={onRowCollapse}
        rowExpansionTemplate={rowExpansionTemplate}
        paginator={true}
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        value={data}
        tableStyle={{ minWidth: "85rem" }}
      >
        <Column expander={allowExpansion} style={{ width: "24px" }} />
        <Column
          headerTooltip="Filter your keyword list by the following conditions: Contains, Begins with, Ends with, Matches RegEx"
          headerTooltipOptions={{ position: "top" }}
          filter
          exportHeader="Keyword"
          header={
            <span className="w-10">
              Keyword{" "}
              <img src={tooltipIcon} className="inline-tooltip-img w-5 h-5" />
            </span>
          }
          field="keyword"
          filterElement={activityRowFilterTemplate}
          sortable
        />
        <Column
          header={
            <span>
              Historical Traffic{" "}
              <img src={tooltipIcon} className="inline-tooltip-img w-5 h-5" />
            </span>
          }
          exportHeader=" Historical Traffic"
          field="historical_Traffic"
          sortable
          body={HistoricalTrafic}
          headerTooltipOptions={{ position: "top" }}
          headerTooltip="Your monthly historical traffic (Semrush) or quarterly historical traffic (GSC) for the associated keyword."
        />
        <Column
          header={
            <>
              Traffic Loss{" "}
              <img src={tooltipIcon} className="inline-tooltip-img w-5 h-5" />
            </>
          }
          exportHeader="Traffic Loss"
          field="traffic_Loss"
          sortable
          body={TraficLoss}
          headerTooltipOptions={{ position: "top" }}
          headerTooltip="The number of visits expected to be lost due to the presence of the AI Snapshot based on your current rankings and industry click-through rate benchmark."
        />
        <Column
          exportHeader="SERP Type"
          header={
            <span>
              SERP Type{" "}
              <img src={tooltipIcon} className="inline-tooltip-img w-5 h-5" />
            </span>
          }
          field="snapshot_shopping_local"
          sortable
          showFilterMenu={false}
          filter
          filterElement={informationsRowFilterTemplate}
          headerTooltipOptions={{ position: "top" }}
          headerTooltip="The AI Snapshot types are categorized into Informational, Shopping, Local, and None. Valuable due to the different AI Overview layouts of these categories."
        />
        <Column
          exportHeader="Mention in Snapshot"
          header={
            <span>
              Mention in Snapshot{" "}
              <img src={tooltipIcon} className="inline-tooltip-img w-5 h-5" />
            </span>
          }
          field="mention_in_snapshot"
          sortable
          headerTooltipOptions={{ position: "top" }}
          headerTooltip="The position of your domain’s citations in the AI Snapshot."
        />
        <Column
          exportHeader="Position In Carousel"
          header={
            <span>
              Position In Carousel{" "}
              <img src={tooltipIcon} className="inline-tooltip-img w-5 h-5" />
            </span>
          }
          field="Position"
          sortable
          showFilterMenu={false}
          filter
          filterElement={positionsRowFilterTemplate}
          headerTooltipOptions={{ position: "top" }}
          headerTooltip="The position of your domain in the AI Snapshot carousel. "
        />
        <Column
          exportHeader="AI Overview Appears?"
          header={
            <span>
              AI Overview Appears?{" "}
              <img src={tooltipIcon} className="inline-tooltip-img w-5 h-5" />
            </span>
          }
          field="status"
          sortable
          showFilterMenu={false}
          filter
          filterElement={statusRowFilterTemplate}
          headerTooltipOptions={{ position: "top" }}
          headerTooltip="The designation of whether the AI Snapshot appeared or did not appear for the associated keyword."
        />
      </DataTable>
    </div>
  );
};

export default DashboardTableComponent;
