import React, { useEffect } from "react";

import logoutImage from "assets/img/logout.png";
import "assets/styles/userdropdown.scss";
import { useFirebase } from "components/Firebase";
import { useHistory } from "react-router-dom";

const UserDropdown = ({ photoURL, initals }) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const { signOut } = useFirebase();
  const history = useHistory();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        dropdownPopoverShow &&
        btnDropdownRef.current &&
        !btnDropdownRef.current.contains(e.target)
      ) {
        console.log(btnDropdownRef.current.contains(e.target));
        setDropdownPopoverShow(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    document.addEventListener("scroll", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      document.removeEventListener("scroll", checkIfClickedOutside);
    };
  }, [dropdownPopoverShow, setDropdownPopoverShow, btnDropdownRef]);

  const handleLogout = async (e) => {
    e.preventDefault();
    await signOut();
    history.push("/");
  };

  return (
    <>
      <div
        className="text-blueGray-500 block relative"
        onClick={(e) => {
          e.preventDefault();
          setDropdownPopoverShow(!dropdownPopoverShow);
        }}
      >
        <div className="items-center flex">
          <span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
            {photoURL ? (
              <img
                alt="..."
                className="w-full rounded-full align-middle border-none shadow-lg"
                src={photoURL}
              />
            ) : (
              <p className="text-black font-bold text-lg">{initals}</p>
            )}
          </span>
        </div>
      </div>
      <div
        ref={btnDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 absolute dropdown_class"
        }
      >
        {/* <div className="h-0 my-2 border border-solid border-blueGray-100" /> */}
        <button
          className={
            "text-sm py-2 px-4 text-left logout_button font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={(e) => handleLogout(e)}
        >
          Logout
          <img src={logoutImage} alt="logout" />
        </button>
      </div>
    </>
  );
};

export default UserDropdown;
