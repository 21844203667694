import React, { useContext, useEffect, useState } from "react";
import "assets/styles/tableView.scss";
import { SessionContext } from "components/Context";
import { Helmet } from "react-helmet";
import axios from "axios";
import { CustomToast } from "utils/helpers";
import { useFirebase } from "components/Firebase";
import CardTableComponent from "components/Table/ProjectsTableComponent";
import { useHistory } from "react-router-dom";
import CardTableSkeleton from "components/Cards/CardTable.skeleton";
import moment from "moment";
import { Link } from "react-router-dom";
import NestedTableComponent from "components/Table/NestedTableComponent";
import ProjectsTable from "components/Table/ProjectsTable";

export default function Tables() {
  const { user, isAwaitingProjects, setIsAwaitingProjects } =
    useContext(SessionContext);

  const [projects, setProjects] = useState([]);
  const { getCurrentUserAccessToken } = useFirebase();
  console.log(user);
// console.log(projects?.find(({pid})=>pid === "q0P-XUszE0WpYKANMV"));

  const getAllProjects = async () => {
    try {
      const accessToken = await getCurrentUserAccessToken();

      const {
        data: {
          data: { projects = [] },
        },
      } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/projects/`, {
        headers: {
          Authorization: accessToken,
        },
      });
      setProjects(projects);
    } catch (error) {
      const {
        response: { data },
      } = error || {};

      const { error: err } = data || {};
      CustomToast(err || data || "Unexpected");
    }
  };

  useEffect(async () => {
    if (user && projects?.length === 0) {
      setIsAwaitingProjects(true);
      await getAllProjects();
      setIsAwaitingProjects(false);
    }
    if (projects?.length > 0) {
      const intervalId = setInterval(() => {
        getAllProjects();
      }, 10000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [user]);

  const columns = [
    {
      name: "Project Name",
      selector: "name",
      sortable: true,
      filterable: true,
    },
    {
      name: "Domain Name",
      selector: "domain",
      sortable: true,
      filterable: true,
      cell: (d) => (
        <a href={d.domain} target="_blank" className="dlink">
          {d.domain}
        </a>
      ),
    },
    {
      name: "Number Of Keywords",
      selector:"number_of_keywords",
      sortable: true,
      filterable: true,
    },
    {
      name: "Project User",
      selector: "full_name",
      sortable: true,
      cell: ({ owner: { full_name } }) => (
        <span className={"ml-3 font-normal " + "text-blueGray-600"}>
          {full_name}
        </span>
      ),
      filterable: true,
    },
    {
      name: "Status",
      selector: "statusText",
      sortable: true,
      filterable: true,
      cell: (d) => {
        const { status, statusText } = d?.jobs[0]
        return (
          <div className="flex flex-col gap-1 items-center w-full">
            <span className="mr-2 w-left ">{statusText}</span>
            <div className="relative w-right">
              <div className="overflow-hidden h-2 text-xs flex rounded bg-red-200">
                <div
                  style={{ width: `${status * 100}%` }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-400"
                ></div>
              </div>
            </div>
          </div>
        )
      },
    },

    {
      name: "Data Started MM/DD/YY",
      selector:"created_at",
      sortable: true,
      filterable: true,
      cell: ({ created_at }) => (
        <span className={"ml-3 font-normal " + "text-blueGray-600"}>
          {moment(created_at).format("MM/DD/YY")}
        </span>
      ),
      filterable: true,
    },

    {
      name: "Data Completed MM/DD/YY",
      selector:"date_completed",
      sortable: true,
      cell: (d) => {
        const { date_completed } = d?.jobs[0]
        return (

          date_completed ?
            <span className={"ml-3 font-normal " + "text-blueGray-600"}>
              {moment(date_completed).format("MM/DD/YY")}
            </span>
            :
            <span className={"ml-3 font-normal " + "text-blueGray-600"}>
             None
            </span>
        )
      },
    },

  ];

  const history = useHistory();

  const onRowClick = (e) => {
    const {
      jid,
      status,
      pid
    } = e
      if (status * 100 > 0) {
        history.push(`/admin/tables/${pid}/${jid}`);
      } else {
        CustomToast(
          "Feel free to return later as the data is currently in the process of being analyzed."
        );
      }
   

  };

  const tableData = {
    columns,
    data: projects,
    print: false,
    filter: false,
    exportHeaders:true,
    fileName: "Serpentor Projects",
  };

  const NoDataFoundComponent = () => {
    const color = "light";
    return (
      <div className="pb-10 flex flex-col overflow-x-auto bg-white parent_no_row_found">
        <div className="flex justify-between ">
          <div
            className={
              "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
              (color === "light"
                ? " text-blueGray-500 border-blueGray-100"
                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
            }
          >
            Project Name
          </div>
          <div
            className={
              "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
              (color === "light"
                ? " text-blueGray-500 border-blueGray-100"
                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
            }
          >
            Domain Name
          </div>
          <div
            className={
              "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
              (color === "light"
                ? " text-blueGray-500 border-blueGray-100"
                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
            }
          >
            Number of Keywords
          </div>
          <div
            className={
              "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
              (color === "light"
                ? " text-blueGray-500 border-blueGray-100"
                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
            }
          >
            Project User
          </div>
          <div
            className={
              "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
              (color === "light"
                ? " text-blueGray-500 border-blueGray-100"
                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
            }
          >
            Status
          </div>
          <div
            className={
              "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
              (color === "light"
                ? " text-blueGray-500 border-blueGray-100"
                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
            }
          >
            Date Started
          </div>
          <div
            className={
              "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
              (color === "light"
                ? " text-blueGray-500 border-blueGray-100"
                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
            }
          >
            Date Completed
          </div>

        </div>
        <span
          className={
            "py-6 font-medium text-center w-full" + "text-blueGray-600"
          }
        >
          There are currently no existing projects. Please add a project to
          view.
        </span>
        <div className="w-full flex items-center justify-center">
          <Link
            className="bg-lightBlue-600 w-fit text-center text-white button_add_project text-sm uppercase font-bold px-6 py-4"
            to="/admin/creatview"
          >
            Add Project
          </Link>
        </div>
      </div>
    );
  };
  const ExpandedComponent = ({ data }) => {

    const nestedColumns = [
      {
        name: "Job Id",
        selector: row => row["jid"],
        sortable: true,
      },
      {
        name: "status",
        selector: row => row["statusText"],
        sortable: true,
      },
      {
        name: "Status Progress",
        selector: row => row["status"],
        sortable: true,
        cell: (d) => {

          return (
            <div className="flex flex-col gap-1 items-center w-full">
              <span className="mr-2 w-left ">{d.statusText}</span>
              <div className="relative w-right">
                <div className="overflow-hidden h-2 text-xs flex rounded bg-red-200">
                  <div
                    style={{ width: `${d.status * 100}%` }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-400"
                  ></div>
                </div>
              </div>
            </div>
          )
        },
      },
      {
        name: "Date Started",
        selector: row => row["created_at"],
        sortable: true,
        cell: ({ created_at }) => (
          <span className={"ml-3 font-normal " + "text-blueGray-600"}>
            {moment(created_at).format("MM/DD/YY")}
          </span>
        ),
      },
      {
        name: "Date Completed",
        selector: row => row["date_completed"],
        sortable: true,
        cell: ({ date_completed }) => {
          return (
            date_completed ?
              <span className={"ml-3 font-normal " + "text-blueGray-600"}>
                {moment(date_completed).format("MM/DD/YY")}
              </span>
              :
              <span className={"ml-3 font-normal " + "text-blueGray-600"}>
                None
              </span>

          )
        },
      },
    ];

    return (
      <div className="px-6 py-6">
        <NestedTableComponent
          data={data?.jobs?.slice(1, data?.jobs?.length)?.map((node) => {
            return {
              ...node,
              pid: data?.pid
            }
          })}
          heading="Jobs"
          onRowClick={(e) => onRowClick(e)}
          columns={nestedColumns}
          noDataComponent={<NoDataFoundComponent />}
          progressComponent={<CardTableSkeleton />}
        />
      </div>
    )
  };

  const onRowClickParent = (e) => {
    const { jobs, pid } = e
    const { status } = jobs[0]
    if (status * 100 > 0) {
      history.push(`/admin/tables/${pid}`);
    } else {
      CustomToast(
        "Feel free to return later as the data is currently in the process of being analyzed."
      );
    }
  }

  const rowPreDisabled = (row) => {
    const { jobs = [] } = row || {}
    if (jobs?.length <= 1) {
      return true
    }
    else {
      return false
    }
 
  };

  const allProjects = projects.map((node) => ({
    ...node,
    full_name:node?.owner?.full_name
  }))
  console.log(isAwaitingProjects);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Projects | Serpentor</title>
        <meta name="description" content="Project listings for Serpentor" />
      </Helmet>
      <div className="flex flex-wrap table_container ">
        <div className="w-full  px-4">
          {/* <CardTableComponent
            progressPending={isAwaitingProjects}
            data={projects || []}
            heading="Projects"
          
            columns={columns}
            onRowClick={(e) => onRowClickParent(e)}
            noDataComponent={<NoDataFoundComponent />}
            progressComponent={<CardTableSkeleton />}
            tableData={tableData}
            expandableRows
            expandableRowDisabled={rowPreDisabled}
            expandableRowsComponent={ExpandedComponent}
          /> */}
          <ProjectsTable 
          loading={isAwaitingProjects}
                data={allProjects || []}
           heading="Projects"
          />
        </div>
      </div>
    </>
  );
}
