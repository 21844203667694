import React from "react";

const Loader = () => {
  return (
    <section className="relative w-full h-full py-40 min-h-screen flex items-center">
      <div
        className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
        style={{
          backgroundImage:
            "url(" + require("assets/img/register_bg_2.png").default + ")",
        }}
      ></div>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <span className="loader"></span>
        </div>
      </div>
    </section>
  );
};

export default Loader;
