import React from "react";
import Skeleton from "react-loading-skeleton";
import "assets/styles/guageChart.scss";

const GuageChartSkeleton = () => {
  return (
    <div className="w-full flex flex-col rooot_class_skeketon_chart justify-between h-full shadow-lg bg-white px-4 py-4">
      <div>
        <Skeleton width="30%" height={10} />
        <Skeleton width="50%" height={20} />
      </div>
      <div className="flex items-center justify-center">
        <Skeleton width={250} height={250} className="rounded-full" />
      </div>
      <div className="flex gap-2">
        <div className="flex gap-1 items-center justify-center">
          <Skeleton width={20} height={20} className="rounded-full" />
          <Skeleton width={50} height={10} />
        </div>
        <div className="flex gap-1">
          <Skeleton width={20} height={20} className="rounded-full" />
          <Skeleton width={50} height={10} />
        </div>
      </div>
    </div>
  );
};

export default GuageChartSkeleton;
