import React from "react";
import HeaderStatsSkeleton from "components/Headers/HeaderStats.skeleton.jsx";
import CtaSection from "components/reports/CtaSection";
import CardPageVisitsSkeleton from "components/Cards/CardPageVisits.skeleton";
import HistogramSkeleton from "components/Cards/Histogram.skeleton";
import GuageChartSkeleton from "components/Cards/GuageChart.skeleton";

const DashboardSkeleton = () => {
  return (
    <>
      <HeaderStatsSkeleton />
      <div className="relative dashaboard_class_top">
        <div className="dashbaord-charts-container mb-8">
          <GuageChartSkeleton />
          <GuageChartSkeleton />
          <GuageChartSkeleton />

          <HistogramSkeleton />
          <HistogramSkeleton />
          <HistogramSkeleton />
        </div>

        <div className="flex flex-wrap container_page_visit_stats mt-4">
          <div className="w-full  ">
            <CardPageVisitsSkeleton />
          </div>
        </div>

        <div className=" container_report_cta mt-4 mb-12 xl:mb-0">
          <div className="bg-white w-full shadow-lg py-4">
            <CtaSection />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardSkeleton;
