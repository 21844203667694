import axios from "axios";
import { useFirebase } from "components/Firebase/hooks";
import React, { useState, useEffect, createContext } from "react";
import { CustomToast } from "utils/helpers";

const SessionContext = createContext({});

const SessionContextProvider = (props) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAwaitingProjects, setIsAwaitingProjects] = useState(true);
  const [projectName, setProjectName] = useState("");

  const [error, setError] = useState();

  const { sessionObserver, getUser } = useFirebase();

  const getUserData = async (authState) => {
    if (authState) {
      const response = await getUser(authState?.uid);

      setUser(() => {
        return { ...authState, ...response };
      });
    } else {
      setUser(() => {
        return authState;
      });
    }
  };



  useEffect(() => {
    const unsubscribe = sessionObserver(async (authState) => {
      await Promise.all([getUserData(authState)]);
      setLoading(false);
    }, setError);

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  error && console.error(error);
  return (
    <SessionContext.Provider
      value={{
        user,
        loading,
        setUser,
        isAwaitingProjects,
        setIsAwaitingProjects,
        setProjectName,
        projectName
      }}
      {...props}
    />
  );
};

export { SessionContext, SessionContextProvider };
