import clsx from "clsx";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

const FileInput = ({
  label,
  name,
  dropzoneToAccept,
  setState,
  file,
  error,
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    setState((state) => {
      return {
        ...state,
        [name]: acceptedFiles[0],
      };
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onClick: true,
    accept: dropzoneToAccept,
  });
  const displayedFilename = file ? file.name : null;

  return (
    <>
      <label
        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
        htmlFor="keywords"
      >
        {label}
      </label>

      <span
        {...getRootProps()}
        className={clsx(
          error
            ? "file_input_error"
            : "shadow focus:outline-none focus:ring active:outline-none placeholder-blueGray-300 text-blueGray-600",
          "btn btn-file flex gap-2 items-center border-0 px-3 py-3   bg-white rounded text-sm  w-full ease-linear transition-all duration-150"
        )}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 640 512"
        >
          <path
            className={clsx(error && "svg_class_style_cloud_warning")}
            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
          />
        </svg>
        Browse
        {displayedFilename && <p>{displayedFilename}</p>}
        {error && (
          <div className="absolute icon_abolute_class_file">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.00003 1.12109C5.53841 1.12109 5.20166 1.399 4.91355 1.76103C4.63174 2.11513 4.33186 2.63313 3.961 3.27374L3.94464 3.30199L1.91353 6.81027L1.89716 6.83854C1.52489 7.48153 1.22394 8.00133 1.05666 8.4233C0.885707 8.85455 0.812157 9.28615 1.04328 9.68702C1.2744 10.0879 1.68476 10.2405 2.14364 10.3086C2.59263 10.3753 3.19326 10.3752 3.93625 10.3752H3.96892H8.03113H8.06381C8.80679 10.3752 9.40742 10.3753 9.85642 10.3086C10.3153 10.2405 10.7257 10.0879 10.9568 9.68702C11.1879 9.28615 11.1143 8.85456 10.9434 8.42331C10.7761 8.00135 10.4752 7.48156 10.1029 6.8386L10.1029 6.83855L10.0865 6.81027L8.05541 3.302L8.03906 3.27374C7.6682 2.63313 7.36831 2.11513 7.08651 1.76103C6.7984 1.399 6.46165 1.12109 6.00003 1.12109ZM6.37503 4.50046C6.37503 4.29335 6.20713 4.12546 6.00003 4.12546C5.79292 4.12546 5.62503 4.29335 5.62503 4.50046V6.50046C5.62503 6.70757 5.79292 6.87546 6.00003 6.87546C6.20713 6.87546 6.37503 6.70757 6.37503 6.50046V4.50046ZM6.50003 8.00046C6.50003 8.2766 6.27617 8.50046 6.00003 8.50046C5.72388 8.50046 5.50003 8.2766 5.50003 8.00046C5.50003 7.72432 5.72388 7.50046 6.00003 7.50046C6.27617 7.50046 6.50003 7.72432 6.50003 8.00046Z"
                fill="#F52419"
              />
            </svg>
          </div>
        )}
        <input
          type="file"
          id="inputName"
          style={{ display: "none" }}
          name={name}
          {...getInputProps()}
        />
      </span>
    </>
  );
};

export default FileInput;
