import React from "react";
import Skeleton from "react-loading-skeleton";

// components

import CardStats from "components/Cards/CardStats.js";
import "assets/styles/headerStats.scss";

export default function HeaderStatsSkeleton() {
  return (
    <div className="relative shadow-lg">
      {/* Header */}
      <div className="restricted_container md:pt-32 root_class_header_stats pt-12">
        <div className="md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="header_stats_container">
              <div className="bg-white flex justify-between items-start w-full shadow-lg px-4 py-4">
                <div className="card_stat_left">
                  <Skeleton width="100%" height={10} />
                  <Skeleton width="50%" height={10} />
                  <Skeleton width={100} height={20} />
                </div>
                <div className="card_stat_right">
                  <Skeleton width={45} height={45} className="rounded-full" />
                </div>
              </div>
              <div className="bg-white flex justify-between items-start w-full shadow-lg px-4 py-4">
                <div className="card_stat_left">
                  <Skeleton width="100%" height={10} />
                  <Skeleton width="50%" height={10} />
                  <Skeleton width={100} height={20} />
                </div>
                <div className="card_stat_right">
                  <Skeleton width={45} height={45} className="rounded-full" />
                </div>
              </div>
              <div className="bg-white flex justify-between items-start w-full shadow-lg px-4 py-4">
                <div className="card_stat_left">
                  <Skeleton width="100%" height={10} />
                  <Skeleton width="50%" height={10} />
                  <Skeleton width={100} height={20} />
                </div>
                <div className="card_stat_right">
                  <Skeleton width={45} height={45} className="rounded-full" />
                </div>
              </div>
              <div className="bg-white flex justify-between items-start w-full shadow-lg px-4 py-4">
                <div className="card_stat_left">
                  <Skeleton width="100%" height={10} />
                  <Skeleton width="50%" height={10} />
                  <Skeleton width={100} height={20} />
                </div>
                <div className="card_stat_right">
                  <Skeleton width={45} height={45} className="rounded-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:px-10 mx-auto w-full mt-4">
          <Skeleton width="100%" height={40} />
        </div>
      </div>
    </div>
  );
}
