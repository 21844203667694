import React from "react";
import ReactApexChart from "react-apexcharts";
import "assets/styles/pieChart.scss";
import clsx from "clsx";
import { Tooltip } from "primereact/tooltip";
import tooltipIcon from "../../assets/img/tooltip-icon.png";


export default function PieChart({
  chartData,
  heading,
  subHeading,
  subheadingClass,
  hasData=false,
  tooltip,
  classes,
}) {
  return (
    <>
      <div className="relative flex flex-col min-w-full h-full break-words bg-white w-full shadow-lg rounded px-4 py-4">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1 ">
              <div className=" w-fit">
        {/* {tooltip &&    <p className="tooltip_main_para_card_chart">{tooltip}</p>} */}
              <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold w-fit">
                {heading}{" "}
                <div className="inline-tooltip-img main_container_headers_2">
                  <img
                    src={tooltipIcon}
                    className="inline-tooltip-img w-5 h-5"
                  />
                  {tooltip && <p className="tooltip_main_para_card_chart">{tooltip}</p>}
                </div>
              </h6>
              <h2 className={clsx('w-fit',subheadingClass)}
              >{subHeading}</h2>
            </div>
            </div>
          </div>
        </div>
        {
        hasData?(   
        <div className="relative w-full h-full">
        <div className="relative ">
          <ReactApexChart
            options={chartData || {}}
            type="pie"
            series={chartData?.series||[]}
            className="pieChartContainer"
            width="100%"
            height="100%"
          />
        </div>
        <div className="backgound_overlay_color absolute w-full h-full flex items-center justify-center top-0 left-0">

        {hasData && <p className="font-semibold text-2xl leading-6 text-white">No Snapshot Found</p>} 
        </div>
        </div>
        ) :(
          <div className=" w-full h-full">
            <ReactApexChart
              options={chartData?.options || {}}
              type="pie"
              series={chartData?.series || []}
              className="pieChartContainer"
              width="100%"
              height="100%"
            />
          </div>
        )
      }

      </div>
    </>
  );
}
