import React, { useContext, useEffect, useState } from "react";

// components

import CardStats from "components/Cards/CardStats.js";
import "assets/styles/headerStats.scss";
import { useParams, useHistory } from "react-router-dom";
import { SessionContext } from "components/Context";
import axios from "axios";
import { CustomToast, toTitleCase } from "utils/helpers";
import clsx from "clsx";
import moment from "moment";
import { useFirebase } from "components/Firebase";
const SCHEDULE = [
  {
    title: "One time",
    value: "0",
  },
  {
    title: "Daily",
    value: "1",
  },
  {
    title: "Weekly",
    value: "2",
  },
  {
    title: "Monthly",
    value: "3",
  },
];

export default function HeaderStats({
  traffic_Loss=0,
  crawl_type=0,
  status=0,
  no_of_keywords_crawled_remaing=0,
  name="",
  notes = [],
  getProjectById,
  isPublicView = false,
}) {
  const { user } = useContext(SessionContext);
  const [loading, setLoading] = useState(false);

const {getCurrentUserAccessToken} = useFirebase();


  const schedule = SCHEDULE.find(({ value }) => value === crawl_type)?.title;
  const { id } = useParams();
  const [copied, setCopied] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      note: e.target.elements.note.value,
    };
    const accessToken = await getCurrentUserAccessToken();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/projects/${id}/notes`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
          },
        }
      );
      if (response?.status === 200) {
        
        e.target.elements.note.value=""
        CustomToast("Successfully added notes to the project");
        getProjectById()
      }
      setLoading(false);
    } catch (error) {
      const {
        response: {
          data: { errors },
        },
      } = error;
      CustomToast(errors?.messages.toString());
      setLoading(false);
    }
  };

  const copy = () => {
    if (typeof window !== "undefined") {
      setCopied(true);
      const el = document.createElement("input");
      el.value = `${window?.location?.origin}/share/${id}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      const newTab = window.open(
        `${window?.location?.origin}/share/${id}`,
        "_blank"
      );
      if (newTab) {
        newTab.focus();
      } else {
        console.error(
          "Unable to open the URL in a new tab. Please check your browser settings."
        );
      }
    }
  };

  useEffect(() => {
    let timer;
    if (copied) {
      timer = setTimeout(() => setCopied(false), 1000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [copied]);



  const handleDeleteNote = async(e,noteId)=>{
    e.preventDefault();
    const accessToken = await getCurrentUserAccessToken();
    try {
        const response = await axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}/v1/projects/${id}/notes/${noteId}`,
          {
            headers: {
              Authorization: accessToken,
            },
          }
        );
        if(response?.status === 200){
         CustomToast("Successfully Delete Note");
         getProjectById();
        }
      } catch (error) {
        const {
          response: {
            data,
          },
        } = error || {};
        const { error: err } = data || {}
        CustomToast(err || data ||"Unexpected");
      }
  }


   return (
    <div className="relative bg-lightBlue-600">
      <div
        className={clsx(
          isPublicView ? "restricted_container_public" : "restricted_container",
          "md:pt-32 root_class_header_stats pt-12"
        )}
      >
        <div className="md:px-10 mx-auto w-full">
          <div>
            <div className="header_stats_container">
              <CardStats
                tooltip="This number shows the estimated total reduction in website visits due to the appearance of the AI Snapshot based on your industry click-through rate benchmarks."
                value={Math.ceil(traffic_Loss).toLocaleString()}
                statSubtitle="Aggregate traffic loss"
                statIcon={<i className="far fa-chart-bar"></i>}
                statIconColor="bg-red-500"
              />
              <CardStats
              tooltip="This figure shows the total number of uploaded keywords analyzed and how many are left to review."
                statTitle={no_of_keywords_crawled_remaing}
                statSubtitle="No of keywords crawled  & remaining"
                statIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path
                      style={{ fill: "#FFFFFF" }}
                      d="M448 160H320V128H448v32zM48 64C21.5 64 0 85.5 0 112v64c0 26.5 21.5 48 48 48H464c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zM448 352v32H192V352H448zM48 288c-26.5 0-48 21.5-48 48v64c0 26.5 21.5 48 48 48H464c26.5 0 48-21.5 48-48V336c0-26.5-21.5-48-48-48H48z"
                    />
                  </svg>
                }
                statIconColor="bg-orange-500"
              />
              <CardStats
              tooltip="Indicates the completion status of the AI Overviews Threat Report for all uploaded keywords."
                value={Math.ceil(status) || 0}
                showElipses={true}
                statSubtitle="Status"
                statIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path
                      style={{ fill: "#FFFFFF" }}
                      d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"
                    />
                  </svg>
                }
                statIconColor="bg-pink-500"
              />
              <CardStats
              tooltip="Shows whether the report is a one-time event or scheduled to repeat."
                statSubtitle="Schedule"
                statTitle={schedule}
                statIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path
                      style={{ fill: "#FFFFFF" }}
                      d="M158.4 32.6c4.8-12.4-1.4-26.3-13.8-31s-26.3 1.4-31 13.8L81.1 100c-7.9 20.7-3 44.1 12.7 59.7l57.4 57.4L70.8 190.3c-2.4-.8-4.3-2.7-5.1-5.1L46.8 128.4C42.6 115.8 29 109 16.4 113.2S-3 131 1.2 143.6l18.9 56.8c5.6 16.7 18.7 29.8 35.4 35.4L116.1 256 55.6 276.2c-16.7 5.6-29.8 18.7-35.4 35.4L1.2 368.4C-3 381 3.8 394.6 16.4 398.8s26.2-2.6 30.4-15.2l18.9-56.8c.8-2.4 2.7-4.3 5.1-5.1l80.4-26.8L93.7 352.3C78.1 368 73.1 391.4 81.1 412l32.5 84.6c4.8 12.4 18.6 18.5 31 13.8s18.5-18.6 13.8-31l-32.5-84.6c-1.1-3-.4-6.3 1.8-8.5L160 353.9c1 52.1 43.6 94.1 96 94.1s95-41.9 96-94.1l32.3 32.3c2.2 2.2 2.9 5.6 1.8 8.5l-32.5 84.6c-4.8 12.4 1.4 26.3 13.8 31s26.3-1.4 31-13.8L430.9 412c7.9-20.7 3-44.1-12.7-59.7l-57.4-57.4 80.4 26.8c2.4 .8 4.3 2.7 5.1 5.1l18.9 56.8c4.2 12.6 17.8 19.4 30.4 15.2s19.4-17.8 15.2-30.4l-18.9-56.8c-5.6-16.7-18.7-29.8-35.4-35.4L395.9 256l60.5-20.2c16.7-5.6 29.8-18.7 35.4-35.4l18.9-56.8c4.2-12.6-2.6-26.2-15.2-30.4s-26.2 2.6-30.4 15.2l-18.9 56.8c-.8 2.4-2.7 4.3-5.1 5.1l-80.4 26.8 57.4-57.4c15.6-15.6 20.6-39 12.7-59.7L398.4 15.4C393.6 3 379.8-3.2 367.4 1.6s-18.5 18.6-13.8 31l32.5 84.6c1.1 3 .4 6.3-1.8 8.5L336 174.1V160c0-31.8-18.6-59.3-45.5-72.2c-9.1-4.4-18.5 3.3-18.5 13.4V112c0 8.8-7.2 16-16 16s-16-7.2-16-16V101.2c0-10.1-9.4-17.7-18.5-13.4C194.6 100.7 176 128.2 176 160v14.1l-48.3-48.3c-2.2-2.2-2.9-5.6-1.8-8.5l32.5-84.6z"
                    />
                  </svg>
                }
                statIconColor="bg-lightBlue-500"
              />
            </div>
          </div>
        </div>
        {!isPublicView && (
          <>
            <form
              action=""
              onSubmit={handleSubmit}
              className="md:px-10 mx-auto w-full mt-4"
            >
              <textarea
                placeholder="Enter notes about report"
                name="note"
                required
                className="border-0 px-3 py-3 placeholder-blueGray-300 normal-input text-blueGray-600 bg-white rounded text-sm shadow  w-full focus:nooutline "
              />
              <div className="flex items-start justify-start pt-2">
                <button
                  type="submit"
                  className={clsx(
                    loading
                      ? "button_loading_style_notes px-8 py-2 text-lg leading-6 font-medium"
                      : "bg-white button_class_style text-lg leading-6 font-medium  "
                  )}
                >
                  {loading && (
                    <span className="button-loader-icon">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10 18.25C9.58579 18.25 9.25 17.9142 9.25 17.5V14.714C9.25 14.2998 9.58579 13.964 10 13.964C10.4142 13.964 10.75 14.2998 10.75 14.714V17.5C10.75 17.9142 10.4142 18.25 10 18.25ZM10 6.03618C9.58578 6.03618 9.25 5.70039 9.25 5.28618V2.5C9.25 2.08579 9.58578 1.75 10 1.75C10.4142 1.75 10.75 2.08579 10.75 2.5V5.28618C10.75 5.70039 10.4142 6.03618 10 6.03618ZM4.16657 14.773C3.87368 15.0658 3.87368 15.5407 4.16657 15.8336C4.45946 16.1265 4.93434 16.1265 5.22723 15.8336L7.1972 13.8636C7.49009 13.5708 7.49009 13.0959 7.1972 12.803C6.9043 12.5101 6.42943 12.5101 6.13654 12.803L4.16657 14.773ZM12.803 6.13648C12.5102 6.42937 12.5102 6.90424 12.803 7.19714C13.0959 7.49003 13.5708 7.49003 13.8637 7.19714L15.8338 5.22701C16.1267 4.93412 16.1267 4.45924 15.8338 4.16635C15.5409 3.87346 15.0661 3.87346 14.7732 4.16635L12.803 6.13648ZM2.5 9.25C2.08579 9.25 1.75 9.58579 1.75 10C1.75 10.4142 2.08579 10.75 2.5 10.75H5.28596C5.70017 10.75 6.03596 10.4142 6.03596 10C6.03596 9.58579 5.70017 9.25 5.28596 9.25H2.5ZM14.7138 9.25C14.2996 9.25 13.9638 9.58579 13.9638 10C13.9638 10.4142 14.2996 10.75 14.7138 10.75H17.5C17.9142 10.75 18.25 10.4142 18.25 10C18.25 9.58579 17.9142 9.25 17.5 9.25H14.7138ZM14.773 15.8336C15.0659 16.1265 15.5408 16.1265 15.8337 15.8336C16.1266 15.5407 16.1266 15.0658 15.8337 14.773L13.8637 12.803C13.5708 12.5101 13.0959 12.5101 12.803 12.803C12.5102 13.0959 12.5102 13.5708 12.803 13.8636L14.773 15.8336ZM6.13654 7.19714C6.42943 7.49003 6.90431 7.49003 7.1972 7.19714C7.49009 6.90425 7.49009 6.42937 7.1972 6.13648L5.22707 4.16635C4.93418 3.87346 4.45931 3.87346 4.16641 4.16635C3.87352 4.45925 3.87352 4.93412 4.16641 5.22701L6.13654 7.19714Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  )}
                  Submit
                </button>
              </div>
            </form>
          </>
        )}

        {notes?.length > 0 && (
          <div className={!isPublicView?" restricted_msg_container  ":"no_hover"}>
            <h4 className=" leading-6 font-bold text-2xl pb-4 text-white pl-10 ">Notes</h4>
            <div className={clsx(isPublicView ? "inner_container_msg_public": "inner_container_msg","md:px-10")}>
              {notes.map(({ note, created_at ,id}, index) => {
                return (
                  <div
                    key={id}
                    role={!isPublicView && "button"}
                    onClick={(e)=>
                      {
                        if(!isPublicView){
                          handleDeleteNote(e,id)}
                        }
                      }
                    className=" CardContainer flex flex-col gap-2  w-full mesage_container relative px-4 py-4"
                  >
                    <div className="flex justify-between gap-10">
                      <div className="flex gap-2 items-center">
                        <div className="user_icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="20"
                            width="20"
                            viewBox="0 0 448 512"
                          >
                            <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                          </svg>
                        </div>
                        <p className="text-base leading-4 font-medium">
                          {toTitleCase(name)}
                        </p>
                      </div>
                      <p className="text-base leading-4 font-medium">
                        {moment(created_at).format("MM/DD/YYYY")}
                      </p>
                    </div>
                    <p className="px-2 text_justify_class text-sm leading-4 font-normal">
                      {note}
                    </p>
                    <div className="button_delete_note">
                      
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
                  </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
