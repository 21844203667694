import React, { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import CardTableComponent from "components/Table/ProjectsTableComponent";
import PostHTML from "components/PostHtml";
import DashboardTableComponent from "components/Table/DashboardTableComponent";

const STATUS_ARRAY_TO_ENABLED = [
  "HAS_SNAPSHOT",
  "NO_SNAPSHOT",
  "NO_GENERATE_BUTTON",
  "THROTTLED",
];

export default function CardPageVisits({ tableData, retires, keywords, name }) {
  const history = useHistory();
  const { id: pid } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="block w-full overflow-x-auto">
          <DashboardTableComponent
          name={name}
            subHeading={` Retries # ${retires}`}
            heading="Report"
            data={data}
          />
        </div>
      </div>
    </>
  );
}
