import { SessionContext } from "components/Context";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import "assets/styles/loader.scss";
import Loader from "components/Loader";

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useContext(SessionContext);
  const history = useHistory();
  if (loading) {
    return <Loader />;
  }
  if (!loading && !user) {
    history.push("/");
    return null;
  }
  return children;
};

export default ProtectedRoute;
