import React from "react";
import "assets/styles/ctaSection.scss";
import logo from "assets/img/logo.png";
import searchLogo from "assets/img/manifying.png";
import shieldLogo from "assets/img/shield.png";
import bulbLogo from "assets/img/bulb.png";

const CtaSection = ({ toggleForm }) => {

  return (
    <div className="cta_section_root">
      <div className="px-4 flex items-center gap-3 flex-wrap justify-between">
        <img src={logo} alt="logo" className="w-56 h-8" />
      </div>
      <div className="content_wrapper">
        <div className="intro_content">
          <p className="text-lg font-medium leading-6 text-black ">
          Google's AI Overviews (previously SGE) is here. It was officially rolled on May 14th at Google I/O. So the online visibility of your business is at a pivotal juncture. Your AI Overviews Threat Report is more than just data – it's a call to action. The right strategies are crucial now to secure your place in a moment of SEO uncertainty and volatility.
            <span>What’s your next move?</span>
          </p>
          <div className="flex justify-center items-center pt-4">
          <button onClick={()=>toggleForm()} className="contact_us_button_top dropdown_button_hover addition active:outline-none focus:outline-none    font-poppins large_content font-semibold text-2xl text-center w-fit">
          Talk AI Overviews SEO Strategy
                </button>
          </div>

        </div>
      </div>
      <div className=" bg-black py-4 flex items-center justify-center">
        <p className="heading_color text-3xl text-center font-bold px-4 lg:px-0">
        Your SEO and Content Strategies for Google’s AI Overviews
        </p>
      </div>
      <div className="image_content_wrapper">
        <div className="w-left">
          <img
            alt="maginifying_glass"
            src={searchLogo}
            className=" w-full h-full"
          />
        </div>
        <div className="w-right">
          <h3 className="italic font-medium text-2xl pb-10">
          Strategic Defense: Safeguard Your Content  
          </h3>
          <ul className="unordered_list_style">
            <li className="font-medium text-lg ">
              <span>Keyword Portfolio and Content Analysis:</span> Pinpoint content most vulnerable to AI Overviews impact, especially for local and eCommerce sites.
            </li>
            <li className="font-medium text-lg ">
              <span>Content Enhancement: </span>
              Modify your existing content to align with AI Overviews, ensuring visibility opportunities within your keyword’s AI snapshots.
            </li>
          </ul>
        </div>
      </div>
      <div className="image_content_wrapper">
        <div className="w-left">
          <img
            alt="maginifying_glass"
            src={shieldLogo}
            className=" w-full h-full"
          />
        </div>
        <div className="w-right">
          <h3 className="italic font-medium text-2xl pb-10">
          Strategic Offense: Enhance Content for the Customer Journey  
          </h3>
          <ul className="unordered_list_style">
            <li className="font-medium text-lg ">
              <span>Comprehensive User Journey Review:</span> Analyze content at every stage of the buyer's journey for gaps and opportunities. 
            </li>
            <li className="font-medium text-lg ">
              <span>Content Strategy Overhaul:</span>
              Develop and implement a robust strategy to address each customer query, guiding them effectively to the decision stage.
            </li>
          </ul>
        </div>
      </div>
      <div className="image_content_wrapper">
        <div className="w-left">
          <img
            alt="maginifying_glass"
            src={bulbLogo}
            className=" w-full h-full"
          />
        </div>
        <div className="w-right">
          <h3 className="italic font-medium text-2xl pb-10">
          Strategic Innovation: Future-Proof Your SEO  
          </h3>
          <ul className="unordered_list_style">
            <li className="font-medium text-lg ">
              <span>Emerging Trends Analysis: </span> Stay ahead with regular assessments of evolving AI Overviews trends and their impact. 
            </li>
            <li className="font-medium text-lg ">
              <span>Adaptive Content Creation:</span>
              Craft content that not only adapts to current AI Overviews experiments but is also resilient to future changes.
            </li>
          </ul>
        </div>
      </div>

      <div className="background_image_container ">
        <div className="background-color-black">
          <div className="w-full ">
            <div className="py-10 ">
              <p className="heading_color text-3xl text-center font-bold ">
                What’s next?
              </p>
              <div className="content_wrapper_background_image">
                <p className=" font-medium text-lg pt-6">
                AI Overviews represent both a challenge and opportunity for your brand. Turn your AI Overviews Threat Report into an actionable, results-driven strategy. Contact iPullRank today to discuss how we can enhance your content strategy and navigate the impact of AI Overviews together.
                </p>
                <div className="flex items-center justify-center">
                <button className="contact_us_button large_content font-semibold text-2xl text-center" onClick={() => toggleForm()} >Discuss Your AI Overviews SEO Strategy</button>
                </div>
    
              </div>
            </div>
            <div className="blue_color_continer py-6">
              <h3 className="text-white font-medium text-center text-4xl ">
                Navigate Change, Deliver Results
              </h3>
              <p className="text-white font-normal text-center text-3xl pt-2 ">
                Practical. Proactive. Proficient.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CtaSection;
