import clsx from "clsx";
import { Tooltip } from "primereact/tooltip";
import React from "react";
import tooltipIcon from "../../assets/img/tooltip-icon.png";

export default function CardStats({
  statSubtitle = "",
  statTitle = "",
  statIcon = "",
  showElipses = false,
  value = 0,
  statIconColor = "",
  tooltip,
  classes,
}) {
  return (
    <>
      <div
        className={clsx(
          "relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg "
        )}
      >
        <div className="flex-auto p-4">
          <div className="flex flex-wrap h-full w-full">
            <div className="flex flex-col justify-between w-full pr-4 max-w-full flex-grow flex-1">
              <h5
                className={clsx(
                  "text-blueGray-400 uppercase font-bold text-xs "
                )}
              >
                {statSubtitle}{" "}
                <div className="inline-tooltip-img main_container_headers">
                  <img
                    src={tooltipIcon}
                    className="inline-tooltip-img w-5 h-5"
                  />
                  {tooltip && <p className="tooltip_main_para">{tooltip}</p>}
                </div>
              </h5>
              {value ? (
                <span className="font-semibold text-xl text-blueGray-700">
                  {value}
                  {showElipses && "%"}
                </span>
              ) : (
                statTitle && (
                  <span className="font-semibold text-xl text-blueGray-700">
                    {statTitle}
                  </span>
                )
              )}
            </div>
            <div className="relative w-auto pl-4 flex-initial">
              <div
                className={
                  "text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full " +
                  statIconColor
                }
              >
                {statIcon}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
