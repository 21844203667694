import React from "react";
import "assets/styles/dropdown.scss";
import clsx from "clsx";

class SelectDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptionList: false,
      optionsList: [],
      placeholder: "",
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.setState({
      placeholder: this.props.placeholder,
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (
      !e.target.classList.contains("custom-select-option") &&
      !e.target.classList.contains("selected-text")
    ) {
      this.setState({
        showOptionList: false,
      });
    }
  };

  handleListDisplay = () => {
    this.setState((prevState) => {
      return {
        showOptionList: !prevState.showOptionList,
      };
    });
  };

  render() {
    const { optionsList, label, onSelectChange, name, defaultText, error } =
      this.props;
    const { showOptionList, placeholder } = this.state;

    const handleOptionClick = (e) => {
      onSelectChange(e);
      this.setState({
        showOptionList: false,
      });
    };

    return (
      <>
        <label
          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="domain"
        >
          {label}
        </label>
        <div className="custom-select-container  border-0 shadow   focus:nooutline ">
          <div
            className={clsx(
              error
                ? "haserror"
                : showOptionList
                ? "selected-text active  "
                : "selected-text-default"
            )}
            onClick={this.handleListDisplay}
          >
            <p
              className={clsx(
                "leading-[26px] w-fit",
                error
                  ? "error_child_class"
                  : defaultText
                  ? "text-black  font-medium"
                  : " text-blueGray-300  font-normal"
              )}
            >
              {defaultText ? defaultText : placeholder}
            </p>
            {error && (
              <div className="absolute icon_abolute_class_select">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.00003 1.12109C5.53841 1.12109 5.20166 1.399 4.91355 1.76103C4.63174 2.11513 4.33186 2.63313 3.961 3.27374L3.94464 3.30199L1.91353 6.81027L1.89716 6.83854C1.52489 7.48153 1.22394 8.00133 1.05666 8.4233C0.885707 8.85455 0.812157 9.28615 1.04328 9.68702C1.2744 10.0879 1.68476 10.2405 2.14364 10.3086C2.59263 10.3753 3.19326 10.3752 3.93625 10.3752H3.96892H8.03113H8.06381C8.80679 10.3752 9.40742 10.3753 9.85642 10.3086C10.3153 10.2405 10.7257 10.0879 10.9568 9.68702C11.1879 9.28615 11.1143 8.85456 10.9434 8.42331C10.7761 8.00135 10.4752 7.48156 10.1029 6.8386L10.1029 6.83855L10.0865 6.81027L8.05541 3.302L8.03906 3.27374C7.6682 2.63313 7.36831 2.11513 7.08651 1.76103C6.7984 1.399 6.46165 1.12109 6.00003 1.12109ZM6.37503 4.50046C6.37503 4.29335 6.20713 4.12546 6.00003 4.12546C5.79292 4.12546 5.62503 4.29335 5.62503 4.50046V6.50046C5.62503 6.70757 5.79292 6.87546 6.00003 6.87546C6.20713 6.87546 6.37503 6.70757 6.37503 6.50046V4.50046ZM6.50003 8.00046C6.50003 8.2766 6.27617 8.50046 6.00003 8.50046C5.72388 8.50046 5.50003 8.2766 5.50003 8.00046C5.50003 7.72432 5.72388 7.50046 6.00003 7.50046C6.27617 7.50046 6.50003 7.72432 6.50003 8.00046Z"
                    fill="#F52419"
                  />
                </svg>
              </div>
            )}

            <i
              className={clsx(
                "fas fa-chevron-down",
                "transition-all duration-200 absolute icon-class",
                showOptionList && " rotate-180"
              )}
            ></i>
          </div>
          {showOptionList && (
            <ul className="select-options">
              {optionsList.map((option) => {
                return (
                  <li
                    className="custom-select-option"
                    data-name={
                      Array.isArray(optionsList) &&
                      typeof optionsList[0] === "string"
                        ? option
                        : option?.value
                    }
                    itemID={name}
                    content={name}
                    key={
                      Array.isArray(optionsList) &&
                      typeof optionsList[0] === "string"
                        ? option
                        : option?.title
                    }
                    onClick={(e) => handleOptionClick(e)}
                  >
                    {Array.isArray(optionsList) &&
                    typeof optionsList[0] === "string"
                      ? option
                      : option?.title}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </>
    );
  }
}

export default SelectDropdown;
