import React from "react";
import clsx from "clsx";
import "react-toastify/dist/ReactToastify.css";
import "assets/styles/toastmessage.scss";
import { toast } from "react-toastify";

export const ToastMessage = ({ otherClasses, toastLabel }) => {
  const toastMessageClasses = clsx(
    otherClasses,
    "flex justify-between items-center gap-5 "
  );

  return (
    <div className={toastMessageClasses} data-testid="toast-message">
      <div className="flex items-center">
        <p className="font-mono text-black font-bold text-base leading-6 whitespace-nowrap">
          {toastLabel}
        </p>
      </div>
      <button
        className="focus:outline-none active:outline-none"
        aria-label="close"
        onClick={() => toast.dismiss()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 512 512"
        >
          <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
        </svg>
      </button>
    </div>
  );
};

export default ToastMessage;
