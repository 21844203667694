import React, { useEffect, useRef, useState } from "react";
import { CustomToast, toTitleCase } from "utils/helpers";

import "assets/styles/keywordtable.scss";
import DeleteKeyword from "components/Modals/DeleteKeyword";


const DEFAULT_STATE = {
  node: "",
  index: null,
};

const KeywordTable = ({ keywords, setKeywords }) => {
  const [show, setShow] = useState(null);
  const [modalData, setModalData] = useState(DEFAULT_STATE);
  const [isInputEdit, setIsEditInput] = useState({});
  const formRef = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        Object.keys(isInputEdit)?.length > 0 &&
        formRef.current &&
        !formRef.current.contains(e.target)
      ) {
        console.log(formRef.current.contains(e.target));
        setIsEditInput({});
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    document.addEventListener("scroll", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      document.removeEventListener("scroll", checkIfClickedOutside);
    };
  }, [isInputEdit, setIsEditInput, formRef]);
  
  
  const handleDeleteKeyword = (e, node, index) => {
    e.preventDefault();
    setShow("delete");
    setModalData(() => {
      return {
        node,
        index,
      };
    });
  };

const handleEditSubmit=(e,index)=>{
  console.log(e);
  e.preventDefault();
  setKeywords((prev) => {
    const { keywords } = prev;
    return {
      ...prev,
      keywords: keywords.map((keyword, i) => (i === index ? e.target.elements.keyToEdit.value : keyword)),
    };
  });
  setIsEditInput(false);
}

  const handleModalClose = () => {
    setShow(null);
    setModalData(DEFAULT_STATE);
  };

  const handleDeleteSubmit = (e, modalData) => {
    e.preventDefault();
    const { index } = modalData;
    setKeywords((prev) => {
      const { keywords } = prev;
      return {
        ...prev,
        keywords: keywords.filter((_, i) => i !== index),
      };
    });
    setShow(null);
    setModalData(DEFAULT_STATE);
  };
  const handleAdditionKeyword = (e) => {
    e.preventDefault();
    setKeywords((prev) => {
      const { keywords=[] } = prev;
        
      return {
          ...prev,
          keywords:["",...keywords],
        };
    })
  };

  

  return (
    <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
      <div className="flex justify-between items-center pb-1">
        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Keywords
        </h6>
        <button onClick={(e)=>handleAdditionKeyword(e)} className=" dropdown_button_hover addition active:outline-none focus:outline-none  px-4 py-2 flex justify-left items-center gap-3 text-sm font-normal leading-5 font-poppins text-black w-fit">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 448 512"
          >
            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
          </svg>
          Add Keyword
        </button>
      </div>

      <table  className="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th className="px-6 align-middle bg-blueGray-50 text-blueGray-500 border-blueGray-100 border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ">
              Serial No
            </th>
            <th className="px-6 align-middle bg-blueGray-50 text-blueGray-500 border-blueGray-100 border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ">
              keyword
            </th>
           
            <th className=" px-6 align-middle bg-blueGray-50 text-blueGray-500 border-blueGray-100 border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ">
              Delete Keyword
            </th>
          </tr>
        </thead>
        <tbody>
          {keywords?.length > 0 &&
            keywords.map((node, index) => {
              return (
                <tr  key={node}>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span className=" text-blueGray-600 text-xs font-bold">
                      {index + 1}
                    </span>
                  </td>

                  <td onClick={()=>setIsEditInput({[index]:true})} className=" text-blueGray-600 text-xs font-bold">
                   {
                    isInputEdit[index] ?
                    <>
                    <form ref={formRef} className="flex" onSubmit={(e)=>handleEditSubmit(e,index)}>
                    <input 
                    id="keyToEdit"
                    className="outline-0 px-3 py-3 border-0 text-sm  w-full  text-blueGray-600 focus:nooutline"
                    defaultValue={toTitleCase(node)}
                     />
                    <button  type="submit"className=" dropdown_button_hover active:outline-none focus:outline-none  px-4 py-2 flex justify-left items-center gap-3 text-sm font-normal leading-5 font-poppins text-black w-fit">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                      >
                        <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                      </svg>
                    </button>
                    </form>
                    </>
                    :
                    <p className="w-full cursor-pointer ">{toTitleCase(node)}</p>
                   }
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <button onClick={(e)=>handleDeleteKeyword(e,node,index)} className=" dropdown_button_hover active:outline-none focus:outline-none  px-4 py-2 flex justify-center items-center gap-3 text-sm font-normal leading-5 font-poppins text-black w-fit">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                      >
                        <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                      </svg>
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    
      <DeleteKeyword
        title={modalData?.node}
        onSubmit={handleDeleteSubmit}
        handleClose={handleModalClose}
        show={show === "delete"}
        modalData={modalData}
      />

    </div>
  );
};

export default KeywordTable;
