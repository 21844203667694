import React, { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import clsx from "clsx"
// components

import CardPageVisits from "components/Cards/CardPageVisits.js";
import GuageChart from "components/Cards/GuageChart.jsx";
import PieChart from "components/Cards/PieChart.jsx";
import "assets/styles/dashboard.scss";
import Histogram from "components/Cards/Histogram";
import HeaderStats from "components/Headers/HeaderStats.js";
import { useParams, useHistory } from "react-router-dom";
import CtaSection from "components/reports/CtaSection";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  CustomToast,
  average,
  getLoadTimeData,
  getPieChartData,
} from "utils/helpers";

import moment from "moment";
import DashboardSkeleton from "views/admin/Dashboard.skeleton";
import ContactForm from "components/Forms/ContactForm";
import { hideBodyScroll, showBodyScroll } from "utils/helpers";

const SHOWN_COLOR_CHART = ["#4472C4", "#ED7D31", "#A5A5A5", "#FFC000"];

const LOCAL_CHARTS_COLORS = ["#FFC000", "#A5A5A5", "#ED7D31", "#4472C4"];

const snapshot_type = [
  {
    0: "None",
  },
  {
    1: "Informational",
  },
  {
    2: "Shopping",
  },
  {
    3: "Local",
  },
];
const DEMO_CHART_PIE =
  [
    {
      "key": "HAS_SNAPSHOT",
      "value": 12
    },
    {
      "key": "NO_SNAPSHOT",
      "value": 4
    }
  ]

export default function PublicView() {
  const [response, setResponse] = useState({});
  const history = useHistory();
  const [toggleForm, setToggleForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [charts, setCharts] = useState({ shown: {} });
  const { id,sid } = useParams();

  const getProjectById = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/share/${id}/share/${sid}`
      );
      if (res.status === 200) {
        const {
          data: { data },
        } = res;
        const {project:{keywords}} = data ||{};

        const response = data?.data?.data
            const combinedData = response?.keyword
        ?.map((node, i) => ({
          Position:  !isNaN(parseFloat(response?.Position[i])) && isFinite(response?.Position[i]) ? Math?.ceil(response?.Position[i])?.toLocaleString(): response?.Position[i],
          historical_Traffic: response?.historical_Traffic[i],
          keyword:node,
          id: keywords?.find((knode) => knode?.keyword === node)?.id,
          status: keywords?.find((knode) => knode?.keyword === node)?.status,
          mention_in_snapshot: response?.mention_in_snapshot[i],
          snapshot_shopping_local:
            snapshot_type[response?.snapshot_shopping_local[i]][
              response?.snapshot_shopping_local[i]
            ],
          traffic_Loss: response?.traffic_Loss[i],
          defaultExpanded: false,
        }))?.sort((a, b) => b.traffic_Loss - a.traffic_Loss)

       setResponse((prev)=>{
         return {
           ...prev,
           ...data,
           tableData:combinedData,
           defaultDataTable:combinedData
         }
       });
      }
      if (res?.data?.error) {
        CustomToast();
        //
      }
    } catch (error) {
      const {
        response: { data },
      } = error || {};

      const { error: err } = data || {};
      CustomToast(err || data || "Unexpected");
      console.log(error);
      history.push("/");
    }
  };
  useEffect(() => {
    getProjectById();
    const intervalId = setInterval(() => {
      if (
        (response?.data?.number_of_keywords_processed /
          response?.data?.keyword_count) *
          100 <
        100
      ) {
        getProjectById();
      }
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, [id]);
  const {
    project: { keywords = [], created_at = "", domain = "" } = {},
    data: { number_of_keywords_processed = 0, keyword_count = 0 } = {},
  } = response || {};
  const statusKeywords = keywords?.filter(({ status }) => status !== null);
  const noOfRetries = keywords?.map(({ retries }) => retries);
  const statusOfProject = (number_of_keywords_processed / keyword_count) * 100;

  useEffect(() => {
    if (!response || !response.data) {
      setLoading(true);
      return;
    }

    if (response) {
      setLoading(false);
    }
    const {
      data: {
        show_or_not_shown = [],
        snapshot_local_chart = [],
        carousel_positions,
        top_ten_organic_positions,
      } = {},
    } = response || {};
    if (show_or_not_shown?.length === 0) {
      const response = getPieChartData(DEMO_CHART_PIE, SHOWN_COLOR_CHART);

      setCharts((prev) => {
        return {
          ...prev,
          shown: response,
        };
      });
    }
    if (Array.isArray(show_or_not_shown) && show_or_not_shown?.length > 0) {
      let lastClicked = null;
      const handleClick = (e) => {
        const clicked = e;
        console.log("hello-world",clicked);
        if (clicked === lastClicked) {
           // Set tableData to an empty array
           setResponse((prev) => ({
             ...prev,
             tableData: response?.defaultDataTable
           }));
        } else {
           // If a different string is clicked, filter the data
           const filteredData = response.tableData.filter((node) => node?.status === clicked);
       
           // Update the response with the filtered data
           setResponse((prev) => ({
             ...prev,
             tableData: filteredData
           }));
       
           // Update lastClicked with the current clicked string
           lastClicked = clicked;
        }
       };
      const chartRes = getPieChartData(show_or_not_shown, SHOWN_COLOR_CHART,handleClick);

      setCharts((prev) => {
        return {
          ...prev,
          shown: chartRes,
        };
      });
    }
    if (snapshot_local_chart?.length === 0) {
      const response = getPieChartData(DEMO_CHART_PIE, SHOWN_COLOR_CHART);

      setCharts((prev) => {
        return {
          ...prev,
          local_charts: response,
        };
      });
    }
    if (
      Array.isArray(snapshot_local_chart) &&
      snapshot_local_chart?.length > 0
    ) {
      let lastClickedSnap = null;
      const handleClick = (e) => {
        if (e === lastClickedSnap) {
           setResponse((prev) => ({
             ...prev,
             tableData: response?.defaultDataTable
           }));
        } else {
          console.log(e);
           const filteredbySnapshotData = response?.defaultDataTable?.filter((node) => node?.snapshot_shopping_local === e);
           setResponse((prev) => ({
             ...prev,
             tableData: filteredbySnapshotData
           }));
       
           lastClickedSnap = e;
        }
       };
      const responseSnaped = getPieChartData(
        snapshot_local_chart,
        LOCAL_CHARTS_COLORS,
        handleClick,
      );

      setCharts((prev) => {
        return {
          ...prev,
          local_charts: responseSnaped,
        };
      });
    }
    if (Array.isArray(statusKeywords) && statusKeywords?.length > 0) {
      const response = getLoadTimeData(statusKeywords);

      setCharts((prev) => {
        return {
          ...prev,
          load_time: response,
        };
      });
    }
    if (carousel_positions !== null || carousel_positions !== undefined) {
      let countArray = [0, 1, 2, 3];
      let positionArray = [1, 2, 4, 3];
      let avg = 0;
      if (JSON.stringify(carousel_positions["Count"]) !== "{}") {
        countArray = Object.values(carousel_positions["Count"])?.map(Number);
      }
      if (JSON.stringify(carousel_positions["Position"]) !== "{}") {
        positionArray = Object.values(carousel_positions["Position"])?.map(Number);
      }
      if (positionArray?.length > 0) {
        avg = average(positionArray);
      }

      const resp = countArray.map((node, i) => ({
        dataPointIndexParent: i,
        count: node,
        position: positionArray[i],
        topLabel: "Carousel Position",
        bgClass: avg === node ? "rounded-icon-blue" : "rounded-icon-red",
        title: avg === node ? "Average Count" : "Count",
        secTitle: avg === node ? "Average Position" : "Position",
      }));


      const histogram = {
        series: [
          {
            name: "Positions",
            data: countArray
          },
        ],

        options: {
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: "100%"
                },
              }
            },
            {
              breakpoint: 720,
              options: {
                chart: {
                  width: "100%"
                },
              }
            },
            {
              breakpoint: 1024,
              options: {
                chart: {
                  width: "100%"
                },
              }
            }
          ],
          plotOptions: {
            bar: {
              strokeWidth: 2,
              borderRadius: 5,
              borderRadiusApplication: "end",
            },
          },
          fill: {
            opacity: 0.3,
            colors: [function ({ value }) {
              if (value === avg) {
                return "rgba(32, 120, 255, 0.4)"
              } else {
                return "#ff4040"
              }
            }],
          },
          stroke: {
            width: 2,
            colors: ["rgba(0, 0, 0, 0.3)"],
            opacity: 0.3
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          xaxis: {
            labels: {
              rotate: 0,
            },
            title: {
              text: "Position",
            },
            categories: positionArray,
            tickPlacement: "on",
            axisBorder: {
              show: true,
              color: "#000000",
              offsetX: -5,
              offsetY: 0,
            },
          },

          dataLabels: {
            enabled: false,
            style: {
              fontSize: "12px",
              fontWeight: "bold",
              colors: ["#000000"],
            },
          },
          yaxis: {
            title: {
              text: "Count",
            },

            axisBorder: {
              show: true,
              offsetX: -5,
              offsetY: 0,
              color: "#000000",
            },
          },
          tooltip: {
            onDatasetHover: {
              highlightDataSeries: true,
            },
            custom: function ({ seriesIndex, dataPointIndex, w }) {
              // var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
              const { title, position, secTitle,
                count, topLabel, bgClass } = resp?.find(({ dataPointIndexParent }) => dataPointIndexParent === dataPointIndex);
              return renderToString(
                <div className="flex flex-col gap-2 shadow-lg rounded-tooltip">
                  <div className="top_header px-2 py-2">{topLabel}</div>
                  <div className="px-2 py-2 justify-center items-center flex gap-2 bottom_text">
                    <div className="flex flex-col gap-2">

                      <div className=" flex gap-2 items-center">
                        <div className={bgClass}></div>
                        <p>{title}: </p>
                        <p>{count}</p>
                      </div>
                      <div className="flex gap-2 items-center">
                        <div className={bgClass}></div>
                        <p>{secTitle}: </p>
                        <p>{position}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            },
          },
        },
      };

      setCharts((prev) => {
        return {
          ...prev,
          carousel_Position: histogram,
        };
      });
    }
    if (
      top_ten_organic_positions !== null ||
      top_ten_organic_positions !== undefined
    ) {
      let countArray = [0, 1, 2, 3];
      let positionArray = [1, 2, 4, 3];
      let avg = 0;
      if (JSON.stringify(top_ten_organic_positions["Count"]) !== "{}") {
        countArray = Object?.values(top_ten_organic_positions["Count"])?.map(
          Number
        );
      }
      if (JSON.stringify(top_ten_organic_positions["Position"]) !== "{}") {
        positionArray = Object?.values(
          top_ten_organic_positions["Position"]
        )?.map((node) => node + 1);
      }
      if (positionArray?.length > 0) {
        avg = average(positionArray);
      }


      const resp = countArray.map((node, i) => ({
        dataPointIndexParent: i,
        count: node,
        position: positionArray[i],
        topLabel: "Organic Ranking",
        bgClass: avg === node ? "rounded-icon-blue" : "rounded-icon-red",
        title: avg === node ? "Average Count" : "Count",
        secTitle: avg === node ? "Average Position" : "Position",
      }));


      const histogram = {
        series: [
          {
            name: "Positions",
            data: countArray
          },
        ],
        options: {
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: "100%"
                },
              }
            },
            {
              breakpoint: 720,
              options: {
                chart: {
                  width: "100%"
                },
              }
            },
            {
              breakpoint: 1024,
              options: {
                chart: {
                  width: "100%"
                },
              }
            }
          ],
          plotOptions: {
            bar: {
              strokeWidth: 2,
              borderRadius: 5,
              borderRadiusApplication: "end",
            },
          },
          fill: {
            opacity: 0.3,
            colors: [function ({ value }) {
              if (value === avg) {
                return "rgba(32, 120, 255, 0.4)"
              } else {
                return "#ff4040"
              }
            }],
          },
          stroke: {
            width: 2,
            colors: ["rgba(0, 0, 0, 0.3)"],
            opacity: 0.3
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            labels: {
              rotate: -45,
            },
            title: {
              text: "Position",
            },
            categories: positionArray,
            tickPlacement: "on",
            axisBorder: {
              color: "#000000",
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "12px",
              fontWeight: "bold",
              colors: ["#000000"],
            },
          },
          yaxis: {
            title: {
              text: "Count",
            },

            axisBorder: {
              show: true,
              color: "#000000",
            },
          },
          tooltip: {
            onDatasetHover: {
              highlightDataSeries: true,
            },
            custom: function ({ seriesIndex, dataPointIndex, w }) {
              // var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
              const { title, position, secTitle,
                count, topLabel, bgClass } = resp?.find(({ dataPointIndexParent }) => dataPointIndexParent === dataPointIndex);
     
              return renderToString(
                <div className="flex flex-col gap-2 shadow-lg rounded-tooltip">
                  <div className="top_header px-2 py-2">{topLabel}</div>
                  <div className="px-2 py-2 justify-center items-center flex gap-2 bottom_text">
                    <div className="flex flex-col gap-2">

                      <div className=" flex gap-2 items-center">
                        <div className={bgClass}></div>
                        <p>{title}: </p>
                        <p>{count}</p>
                      </div>
                      <div className="flex gap-2 items-center">
                        <div className={bgClass}></div>
                        <p>{secTitle}: </p>
                        <p>{position}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            },
          },
        },
      };

      setCharts((prev) => {
        return {
          ...prev,
          organic_positions: histogram,
        };
      });
    }
  }, [response]);

  const toggleFunc = () => {
    setToggleForm(!toggleForm);
  };
  useEffect(()=>{
    if(toggleForm){
      hideBodyScroll()
    }
    else{
      showBodyScroll()
    }

  },[toggleForm])

  console.log(response);
  return (
    <>
      {loading ? (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title> Projects Report | Serpentor</title>
            <meta name="description" content="Project listings for Serpentor" />
          </Helmet>
          <DashboardSkeleton />
        </>
      ) : (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              {" "}
              {domain} Serpentor Report for SGE -{" "}
              {moment(created_at).format("MM/DD/YY")}
            </title>
            <meta
              name="description"
              content={`iPullRank SGE analysis for ${domain} from ${moment(
                created_at
              ).format("MM/DD/YY")}`}
            />
          </Helmet>
          <div className="top_bar_clas_public_color">
            {response?.project?.name && (
              <div className="top_bar_class_public">
                {" "}
                {response?.project?.name}
              </div>
            )}
          </div>
        {Object.keys(response?.data || {})?.length >0 &&  <HeaderStats
            isPublicView={true}
            setResponse={setResponse}
            notes={response?.project?.notes || []}
            traffic_Loss={response?.data?.aggregate_traffic_loss || ""}
            status={statusOfProject || 0}
            crawl_type={response?.project?.schedule || ""}
            name={response?.project?.name || ""}
            no_of_keywords_crawled_remaing={
              `${number_of_keywords_processed}/${keyword_count}` || ""
            }
          />}
          <div className="top_bar_clas_public_color relative">
            <div className="relative dashaboard_class_top ">
             {Object.keys(response?.data || {})?.length >0 && <div className="dashbaord-charts-container mb-8">
                <GuageChart
                  threat_rating={response?.data?.threat_rating}
                  subheadingClass="subhaeding_charts"
                />
                <PieChart
                  chartData={charts?.shown}
                  heading="AI SnapShot"
                  subHeading="Shown/Not Shown"
                  subheadingClass="subhaeding_charts"
                />
                <PieChart
                  heading="AI SnapShot"
                  subHeading="Snapshot Type"
                  chartData={charts?.local_charts}
                  subheadingClass="subhaeding_charts"
                />
                <Histogram
                  name="Load Time"
                  heading="AI SnapShot"
                  subHeading="Load Times"
                  subheadingClass="subhaeding_charts"
                  chartData={charts?.load_time}
                />
                <Histogram
                  name="Domain Featured"
                  hasData={
                    JSON.stringify(
                      response?.data?.carousel_positions["Count"]
                    ) === "{}"
                  }
                  heading="Carousel Positions"
                  subHeading="Domain Featured"
                  subheadingClass="subhaeding_charts"
                  chartData={charts?.carousel_Position}
                />
                <Histogram
                  name="Ranking"
                  heading="Top 10 Ranking"
                  subHeading="Ranking"
                  hasData={
                    JSON.stringify(
                      response?.data?.top_ten_organic_positions["Count"]
                    ) === "{}"
                  }
                  subheadingClass="subhaeding_charts"
                  chartData={charts?.organic_positions}
                />
              </div>}

         {Object.keys(response?.data || {})?.length >0 &&     <div className="flex flex-wrap container_page_visit_stats mt-4">
                <div className="w-full  ">
                <CardPageVisits
                name={response?.project?.name}
                  isPublic={true}
                  keywords={keywords || []}
                  tableData={response?.tableData || []}
                  retires={noOfRetries?.reduce(
                    (partialSum, value) => partialSum + value,
                    0
                  )}
                />
                </div>
              </div>}

              <div className=" container_report_cta mt-4 mb-12 xl:mb-0">
                <div className="bg-white w-full shadow-lg py-4">
                <CtaSection toggleForm={toggleFunc} />
                </div>
              </div>
            </div>
          </div>
          <div
        className={clsx(
          'fixed top-0 right-0 flex justify-end h-full z-parent-div overflow-hidden',
          toggleForm ? 'w-full' : 'w-0'
        )}
      >
        <button
          aria-label="button"
          onClick={() => toggleFunc()}
          className="w-full h-full absolute left-0 top-0 form-overlay"
        ></button>
        <ContactForm
          toggleCancel={toggleFunc}
          otherClasses={clsx(
            'z-10 transition-width ',
            'w-full'
          )}
        />
      </div>
        </>
      )}
    </>
  );
}
